<template>
  <div>
    <spinner-loader
      :loadingStuff="loadingStuff"
      :loadIngStuffText="loadIngStuffText"
    />
    <app-template />
    <div
      v-if="
        adminNavigationSelectedTeam === false && 
        showallTeams === true
      "
      class="uk-container uk-container-large container"
    >
      <div class="sessons-header">
        <div class="header-buttons">
          <h2>Teams overview</h2>
        </div>

        <section class="teams-overview-wrapper">
          <ul class="tabs headding-space">
            <li class="tab-item">
              <a
                :class="
                  selectedTabTeamsOverview === 'teams-over-view-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTabTeamsOverview', 'teams-over-view-tab')"
                href="javascript:;"
                >Teams</a
              >
            </li>
          </ul>

          <div class="tabs-wrapper">
            <div
              class="team-over-veiw-tab tab-content"
              v-if="selectedTabTeamsOverview === 'teams-over-view-tab'"
            >
                <div class="number-of-results">
                  <p>Number of teams: {{ clubTeams.length }}</p>
                </div>

                <div class="button-wrapper-top filters-sort">
                  <b-button
                    @click="toggleFilters = !toggleFilters"
                    variant="outline-success filter-small"
                  >
                    <b-icon icon="filter" aria-hidden="true"></b-icon>
                    Filters</b-button
                  >

                  <div class="filters" v-if="toggleFilters === true">
                    <b-form-select
                      class="mb-3"
                      @change="
                        filterBy('allTeamDataBeforFilterApplied', 'teamName')
                      "
                      v-model="selectedFilterData"
                    >
                      <b-form-select-option :value="null" disabled
                        >Select a team</b-form-select-option
                      >
                      <b-form-select-option
                        v-for="(team, index) in clubTeams"
                        :key="team.id + index"
                        :value="team.teamName"
                        >{{ team.teamName }}</b-form-select-option
                      >
                    </b-form-select>
                    <a
                      @click="removeFilter()"
                      v-if="hideRemoveTeamFilterButton === false"
                      href="javascript:;"
                      class="remove-icon-button"
                      ><b-icon icon="x" font-scale="2" aria-hidden="true"></b-icon
                    ></a>
                  </div>
                </div>
                <div class="tab-content">
                  <template v-if="clubTeams.length > 0">
                    <div
                      v-for="(team, index) in clubTeams"
                      :value="team.id + index"
                      :key="team.id"
                      class="team-profile-details"
                      @click="setSelectedTeam(team)"
                    >
                      <div class="user-photo-info">
                        <img :src="getImgUrl('default-image.jpg')" alt="" />
                      </div>
                      <div class="team-info">
                        <h2>{{ team.teamName }}</h2>
                        <p>Age group: {{ team.age_group }}</p>
                        <p>Coach: {{ team.name }}</p>
                      </div>
                      <span
                        class="team-form-results"
                        v-if="allTeamsGameResultsData.length > 0 && userData.userType !== 'User'"
                      >
                        <span
                          v-for="(pastGame, i) in allTeamsGameResultsData"
                          :key="i"
                        >
                          <span
                            v-if="team.teamId == pastGame.teamId"
                            :class="`result-${checkGameResult(pastGame)}`"
                            >{{ checkGameResult(pastGame) }}</span
                          >
                        </span>
                      </span>
                      <span v-else>No game results</span>
                    </div>
                  </template>
                  <div class="empty-state" v-else>
                    <img src="../img/emptyStateIllustration.svg" />
                    <p>No Team information</p>
                  </div>
                </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div v-else class="uk-container uk-container-large container">
      <div class="sessons-header">
        <div class="select-user">
          <template v-if="clubTeams.length > 1">
            <label class="form__label" for="userSelect">Select team </label>
            <select @change="setSelectedTeam($event)" id="userSelect">
              <option
                v-for="(clubTeam, index) in clubTeams"
                :value="clubTeam.teamName"
                :key="clubTeam.id + index"
              >
                {{ clubTeam.teamName }}
              </option>
            </select>
          </template>

          <div class="season_info">
            <h1>{{ selectedTeam.teamName }}</h1>
            <p>Age group: {{ selectedTeam.age_group }}</p>
            <p v-if="userData.userType === 'Coach'">
              Coach: {{ userData.name }}
            </p>
            <p v-else>Coach: {{ selectedTeam.name }}</p>
            <p>
              Season: {{ dayMonthYear(selectedTeam.season_start_date) }} -
              {{ dayMonthYear(selectedTeam.season_end_date) }}
            </p>
          </div>
        </div>
        <!--<div v-if="userData.userType === 'User'" class="header-buttons">
          <span class="week-selected-dates-text">
            <div class="season_info">
              <h1>{{ selectedTeam.teamName }}</h1>
              <p>Age group: {{ selectedTeam.age_group }}</p>
              <p>Coach: {{ selectedTeam.name }}</p>
              <p>
                Season: {{ dayMonthYear(selectedTeam.season_start_date) }} -
                {{ dayMonthYear(selectedTeam.season_end_date) }}
              </p>
            </div>
            <span class="week-selected-dates-text">
              Week number: {{ weekSelectedNumber }} |
              {{ selectedWeekMonSunday }}</span
            ></span
          >
        </div>-->
      </div>
      <template
        v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
      >
      </template>
      <template v-else> </template>
      <button
        @click="showEditAddGamesModal()"
        v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
        class="green-button add-new-programme"
      >
        Add new event
      </button>
      <ul class="tabs headding-space">
        <li class="tab-item">
          <a
            :class="
              selectedTab === 'games-tab' ? 'tab-link active' : 'tab-link'
            "
            @click="tabChange('selectedTab', 'games-tab')"
            href="javascript:;"
            >Games</a
          >
        </li>
        <li class="tab-item">
          <a
            :class="
              selectedTab === 'training-tab' ? 'tab-link active' : 'tab-link'
            "
            @click="tabChange('selectedTab', 'training-tab')"
            href="javascript:;"
            >Training
          </a>
        </li>
        <li class="tab-item">
          <a
            :class="
              selectedTab === 'full-team-tab' ? 'tab-link active' : 'tab-link'
            "
            @click="tabChange('selectedTab', 'full-team-tab')"
            href="javascript:;"
            >Members</a
          >
        </li>
        <li
          class="tab-item">
          <a
            :class="
              selectedTab === 'full-team-chat' ? 'tab-link active' : 'tab-link'
            "
            @click="tabChange('selectedTab', 'full-team-chat')"
            href="javascript:;"
            >Chat</a
          >
        </li>
        <li class="tab-item" 
          v-if="selectedTeam.age_group === '2007' 
          || selectedTeam.age_group === '2008'
          || selectedTeam.age_group === '2009'
          || selectedTeam.age_group === '2010'">
          <a
            :class="
              selectedTab === 'league-tab' ? 'tab-link active' : 'tab-link'
            "
            @click="tabChange('selectedTab', 'league-tab')"
            href="javascript:;"
            >League table</a
          >
        </li>
        <li
          class="tab-item"
          v-if="userData.userType !== 'User'">
          <a
            :class="
              selectedTab === 'team-performance-tab' ? 'tab-link active' : 'tab-link'
            "
            @click="tabChange('selectedTab', 'team-performance-tab')"
            href="javascript:;"
            >Performance</a
          >
        </li>
        <li class="tab-item">
            <a
              :class="
                selectedTab === 'homework-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'homework-tab')"
              href="javascript:;"
              >Homework</a
            >
          </li>
        <li
          class="tab-item"
          v-if="userData.userType !== 'User'">
          <a
            :class="
              selectedTab === 'team-documents-tab' ? 'tab-link active' : 'tab-link'
            "
            @click="tabChange('selectedTab', 'team-documents-tab')"
            href="javascript:;"
            >Documents</a
          >
        </li>
        <li
          class="tab-item"
          v-if="userData.userType !== 'User'">
          <a
            :class="
              selectedTab === 'team-attendance-tab' ? 'tab-link active' : 'tab-link'
            "
            @click="tabChange('selectedTab', 'team-attendance-tab')"
            href="javascript:;"
            >Attendance</a
          >
        </li>
      </ul>
      <div class="tabs-wrapper">
        <div
          class="current-week-tab tab-content"
          v-if="selectedTab === 'games-tab'"
        >

          <div class="search-filter">
            <div class="form__item">
              <label class="form__label" for="picker">
                Select games date range</label
              >
              <date-range-picker
                ref="picker"
                :locale-data="dateRangePickerLocaleData"
                @update="dateRangeSelected()"
                v-model="dateRange"
              >
                <template v-slot:input="picker">
                  <b-icon icon="calendar3"></b-icon>
                  {{ formatMatchDate(picker.startDate) }} -
                  <b-icon icon="calendar3"></b-icon>
                  {{ formatMatchDate(picker.endDate) }}
                </template>
              </date-range-picker>
              <a
                class="clear-daterange"
                v-b-tooltip.hover
                title="Reset date range"
                @click="dateRangeReset()"
                href="javascript:;"
                ><b-icon icon="arrow-counterclockwise"></b-icon
              ></a>
            </div>
          </div>
          <div class="games" v-if="thisWeeksGames.length > 0">
            <p>Upcoming games</p>
            <sorting-by-created-date
              :arrayDataForSorting="thisWeeksGames"
              sortingByValue="matchDate"
              @sorted-data-call="sortedData"
            />
            <div
              v-for="(game, index) in thisWeeksGames"
              :key="index"
              class="game"
              :id="game.id + index"
            >
              <div
                class="game-wrapper"
                @click="showGameDetails(game, 'viewGameDetails')"
              >
                <div class="match-details">
                  <span>{{ game.gameType }}</span>
                  <p v-if="game.result_added == '0'" class="teams">
                    {{ selectedTeam.teamName }} <span>v</span> {{ game.opposition }}
                  </p>
                  <p class="game-info">
                    <span><b-icon icon="calendar3"></b-icon>{{ getDayAndDate(game.matchDate) }}</span>
                    <span><b-icon icon="stopwatch"></b-icon>{{ formatISOStringDateAndTime(game.kickOffTime) }}</span>
                  </p>
                </div>
              </div>
              <div
                v-if="
                  userData.userType === 'Admin' || userData.userType === 'Coach'
                "
                class="player-game-responce"
              >
                <b-button
                  @click="
                    editDeleteGameOptionsModal(
                      game,
                      'viewGameDeleteEditOptions',
                      'Edit'
                    )
                  "
                  variant="outline-warning"
                  v-b-tooltip.hover
                  title="Edit"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon
                ></b-button>
                <b-button
                  @click="
                    editDeleteGameOptionsModal(
                      game,
                      'viewGameDeleteEditOptions',
                      'Delete'
                    )
                  "
                  variant="outline-danger"
                  v-b-tooltip.hover
                  title="Delete"
                  ><b-icon icon="trash" aria-hidden="true"></b-icon
                ></b-button>

                <b-button
                  v-if="
                    userData.userType === 'Admin' ||
                    (userData.userType === 'Coach' && currentTeam.length > 0)
                  "
                  @click="addNewGameresultModal(game, 'addNewGameresultModal')"
                  variant="outline-success"
                  v-b-tooltip.hover
                  title="Add game result"
                  ><b-icon icon="file-text"></b-icon
                ></b-button>

                <template v-if="
                    userData.userType === 'Admin' ||
                    (userData.userType === 'Coach' && currentTeam.length > 0)
                  ">

                  <b-button
                  v-if="checkIfGameHasAnalysis(game.id) === true"
                  @click="gameAnalysisModal(game, 'gameAnalysisModal')"
                  variant="outline-success"
                  v-b-tooltip.hover
                  title="View / Edit Game analysis"
                  ><b-icon icon="eye"></b-icon></b-button>

                <b-button v-else
                  @click="gameAnalysisModal(game, 'gameAnalysisModal')"
                  variant="outline-success"
                  v-b-tooltip.hover
                  title="Add Game analysis"
                  ><b-icon icon="book"></b-icon
                ></b-button>

                </template>

                <span
                  class="recurring-event-icon"
                  v-if="game.recurringEventType !== null"
                  v-b-tooltip.hover
                  title="This is a recurring event"
                  ><b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon>
                </span>
              </div>
            </div>
          </div>
          <template v-else>
            <div class="games">
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No upcoming games</p>
              </div>
            </div>
          </template>

          <div class="games" v-if="pastGames.length > 0">
            <p>
              Past games / results
              <span class="team-form-results">
                <sorting-by-created-date
                  :arrayDataForSorting="pastGames"
                  sortingByValue="matchDate"
                  @sorted-data-call="sortedData"
                />
                <p class="highlighted-text"><b-icon icon="info-circle" aria-hidden="true"></b-icon> Results will be displayed only for games where the outcome has been recorded.</p>
                <span v-for="(pastGame, i) in pastGames" :key="i">
                  <span
                    v-if="pastGame.result_added == '1' && userData.userType !== 'User'"
                    :class="`result-${checkGameResult(pastGame)}`"
                    >{{ checkGameResult(pastGame) }}</span
                  >
                </span>
              </span>
            </p>
            <div
              v-for="(game, index) in pastGames"
              :key="index"
              class="game"
              :id="game.id"
            >
              <div
                class="game-wrapper"
                @click="showGameDetails(game, 'viewGameDetails')"
              >
                <div class="match-details">
                  <span>{{ game.gameType }}</span>
                  <p v-if="game.result_added == '0'" class="teams">
                    {{ selectedTeam.teamName }} <span>v</span> {{ game.opposition }}
                  </p>
                  <div class="with-results" v-else>
                    <p class="teams">
                      {{ selectedTeam.teamName }}
                      <span class="goal-number">{{
                        game.home_team_goals
                      }}</span>
                      <span>v</span>
                      <span class="goal-number">{{
                        game.away_team_goals
                      }}</span>
                      {{ game.opposition }}
                    </p>

                    <div v-if="game.result_added != '0'" class="goal-and-game-events">

                      <span
                        class="goal-scorers-text"
                        v-for="goal in allGameGoalScorersData"
                        :key="goal.id"
                      >

                        <span v-if="goal.game_id === game.id">
                          <img src="./img/goal-icon.svg" /> {{ goal.goal_time }}
                          {{ getPlayerFromId(goal.userId) }}
                          <span v-if="goal.goal_type === 'Fee kick'">(FK)</span>
                          <span v-if="goal.goal_type === 'Pentaly'">(P)</span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <p class="game-info">
                    <span><b-icon icon="calendar3"></b-icon>{{ getDayAndDate(game.matchDate) }}</span>
                    <span><b-icon icon="stopwatch"></b-icon>{{ formatISOStringDateAndTime(game.kickOffTime) }}</span>
                  </p>
                </div>
              </div>
              <div
                v-if="
                  userData.userType === 'Admin' || userData.userType === 'Coach'
                "
                class="player-game-responce"
              >
                <b-button
                  @click="
                    editDeleteGameOptionsModal(
                      game,
                      'viewGameDeleteEditOptions',
                      'Edit'
                    )
                  "
                  variant="outline-warning"
                  v-b-tooltip.hover
                  title="Edit"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon
                ></b-button>
                <b-button
                  @click="
                    editDeleteGameOptionsModal(
                      game,
                      'viewGameDeleteEditOptions',
                      'Delete'
                    )
                  "
                  variant="outline-danger"
                  v-b-tooltip.hover
                  title="Delete"
                  ><b-icon icon="trash" aria-hidden="true"></b-icon
                ></b-button>
                <b-button
                  v-if="
                    userData.userType === 'Admin' ||
                    (userData.userType === 'Coach' && currentTeam.length > 0)
                  "
                  @click="addNewGameresultModal(game, 'addNewGameresultModal')"
                  variant="outline-success"
                  v-b-tooltip.hover
                  title="Add game result"
                  ><b-icon icon="file-text"></b-icon
                ></b-button>

                <template v-if="
                    userData.userType === 'Admin' ||
                    (userData.userType === 'Coach' && currentTeam.length > 0)
                  ">

                  <b-button
                  v-if="checkIfGameHasAnalysis(game.id) === true"
                  @click="gameAnalysisModal(game, 'gameAnalysisModal')"
                  variant="outline-success"
                  v-b-tooltip.hover
                  title="View / Edit Game analysis"
                  ><b-icon icon="eye"></b-icon></b-button>

                <b-button v-else
                  @click="gameAnalysisModal(game, 'gameAnalysisModal')"
                  variant="outline-success"
                  v-b-tooltip.hover
                  title="Add Game analysis"
                  ><b-icon icon="book"></b-icon
                ></b-button>

                </template>
                <span
                  class="recurring-event-icon"
                  v-if="game.recurringEventType !== null"
                  v-b-tooltip.hover
                  title="This is a recurring event"
                  ><b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon>
                </span>
              </div>
            </div>
          </div>
          <template v-else>
            <div class="games">
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No past games</p>
              </div>
            </div>
          </template>
        </div>

        <div
          class="current-week-tab tab-content"
          v-if="selectedTab === 'training-tab'"
        >
        <div class="search-filter">
            <div class="form__item">
              <label class="form__label" for="picker">
                Select training date range</label
              >
              <date-range-picker
                ref="picker"
                :locale-data="dateRangePickerLocaleData"
                @update="dateRangeSelected()"
                v-model="dateRange"
              >
                <template v-slot:input="picker" style="min-width: 350px">
                  <b-icon icon="calendar3"></b-icon>
                  {{ formatMatchDate(picker.startDate) }} -
                  <b-icon icon="calendar3"></b-icon>
                  {{ formatMatchDate(picker.endDate) }}
                </template>
              </date-range-picker>
              <a
                class="clear-daterange"
                v-b-tooltip.hover
                title="Reset date range"
                @click="dateRangeReset()"
                href="javascript:;"
                ><b-icon icon="arrow-counterclockwise"></b-icon
              ></a>
            </div>
          </div>
          <div class="games" v-if="thisWeeksTraining.length > 0">
            <p>Upcoming training</p>
            <sorting-by-created-date
              :arrayDataForSorting="thisWeeksTraining"
              sortingByValue="matchDate"
              @sorted-data-call="sortedData"
            />
            <div
              v-for="(game, index) in thisWeeksTraining"
              :key="index"
              class="game"
              :id="game.id"
            >
              <div
                class="game-wrapper"
                @click="showGameDetails(game, 'viewGameDetails')"
              >
                <div class="match-details">
                  <p class="teams">{{ game.gameType }} <span class="small-text">{{ setPitchZoneText(game) }}</span></p>
                  <p class="game-info">
                    <span><b-icon icon="calendar3"></b-icon>{{ getDayAndDate(game.matchDate) }}</span>
                    <span><b-icon icon="stopwatch"></b-icon>{{ formatISOStringDateAndTime(game.kickOffTime)
                    }}
                    - {{ formatISOStringDateAndTime(game.endTime) }}</span>
                  </p>
                </div>
              </div>
              <div
                v-if="
                  userData.userType === 'Admin' || userData.userType === 'Coach'
                "
                class="player-game-responce"
              >
                <b-button
                  @click="
                    editDeleteGameOptionsModal(
                      game,
                      'viewGameDeleteEditOptions',
                      'Edit'
                    )
                  "
                  variant="outline-warning"
                  v-b-tooltip.hover
                  title="Edit"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon
                ></b-button>
                <b-button
                  @click="
                    editDeleteGameOptionsModal(
                      game,
                      'viewGameDeleteEditOptions',
                      'Delete'
                    )
                  "
                  variant="outline-danger"
                  v-b-tooltip.hover
                  title="Delete"
                  ><b-icon icon="trash" aria-hidden="true"></b-icon
                ></b-button>
                <span
                  class="recurring-event-icon"
                  v-if="game.recurringEventType !== null"
                  v-b-tooltip.hover
                  title="This is a recurring event"
                  ><b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon>
                </span>
              </div>
            </div>
          </div>
          <template v-else>
            <div class="games">
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No upcoming training</p>
              </div>
            </div>
          </template>

          <div class="games" v-if="pastTraining.length > 0">
            <p>Past training</p>
            <sorting-by-created-date
              :arrayDataForSorting="pastTraining"
              sortingByValue="matchDate"
              @sorted-data-call="sortedData"
            />
            <div
              v-for="(game, index) in pastTraining"
              :key="index"
              class="game"
              :id="game.id"
            >
              <div
                class="game-wrapper"
                @click="showGameDetails(game, 'viewGameDetails')"
              >
                <div class="match-details">
                  <p class="teams">{{ game.gameType }} <span class="small-text">{{ setPitchZoneText(game) }}</span></p>
                  <p class="game-info">
                    <span><b-icon icon="calendar3"></b-icon>{{ getDayAndDate(game.matchDate) }}</span>
                    <span><b-icon icon="stopwatch"></b-icon>{{ formatISOStringDateAndTime(game.kickOffTime)
                    }}
                    - {{ formatISOStringDateAndTime(game.endTime) }}</span>
                  </p>
                </div>
              </div>
              <div
                v-if="
                  userData.userType === 'Admin' || userData.userType === 'Coach'
                "
                class="player-game-responce"
              >
                <b-button
                  @click="
                    editDeleteGameOptionsModal(
                      game,
                      'viewGameDeleteEditOptions',
                      'Edit'
                    )
                  "
                  variant="outline-warning"
                  v-b-tooltip.hover
                  title="Edit"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon
                ></b-button>
                <b-button
                  @click="
                    editDeleteGameOptionsModal(
                      game,
                      'viewGameDeleteEditOptions',
                      'Delete'
                    )
                  "
                  variant="outline-danger"
                  v-b-tooltip.hover
                  title="Delete"
                  ><b-icon icon="trash" aria-hidden="true"></b-icon
                ></b-button>
                <span
                  class="recurring-event-icon"
                  v-if="game.recurringEventType !== null"
                  v-b-tooltip.hover
                  title="This is a recurring event"
                  ><b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon>
                </span>
              </div>
            </div>
          </div>
          <template v-else>
            <div class="games">
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No Past training</p>
              </div>
            </div>
          </template>
        </div>

        <div
          class="current-week-tab tab-content team-chat-wrapper"
          v-if="selectedTab === 'full-team-chat'"
        >

        <button
        @click="chatGroupModal()"
        v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
        class="green-button add-new-programme"
      >
      New chat group
      </button>
          <div class="team-chat-wrapper" v-if="chatGroupData.length > 0">
              <div
                v-for="chat in chatGroupData"
                class="team-chat-list-item"
                :key="chat.id"
                @click="chatDetailsModal(chat)"
              >
                 <img :src="getImgUrl('default-image.jpg')" alt="" />
                {{ chat.name }}
              </div>
          </div>
          <div v-else class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No team chats</p>
              </div>
        </div>
        <div
          class="current-week-tab tab-content team-tab-content"
          v-if="selectedTab === 'full-team-tab'"
        >
          <div class="team-content">
            <div class="team-types">
              <div class="full-team" v-if="currentTeam.length > 0">
                <h3>First team</h3>
                <table class="w-a-style">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Number</th>
                      <th>Name</th>
                      <th>Position</th>
                      <th>Age group</th>
                      <th v-b-tooltip.hover title="League game">LG</th>
                      <th v-b-tooltip.hover title="Cup game">CG</th>
                      <th v-b-tooltip.hover title="Training">TR</th>
                      <th v-if="userData.userType === 'Admin' || userData.userType === 'Coach'" v-b-tooltip.hover title="view player profile">Profile</th>
                      <!-- <th v-b-tooltip.hover title="Injury">Inj</th>-->
                      <th>Type</th>
                      <th class="centre-icon">
                        <img src="./img/doc-icon.svg" alt="" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="player in currentTeam"
                      :key="player.id"
                      :class="
                        player.injury === true ? 'injury-table-style' : ''
                      ">
                      <td>
                        <img
                          class="player-photo"
                          v-if="
                            player.userImage === null || player.userImage === ''
                          "
                          :src="getImgUrl('default-image.jpg')"
                          alt=""
                        />
                        <img
                          class="player-photo"
                          v-else
                          :src="getImgUrl(player.userImage)"
                        />
                    
                      </td>
                      <td>{{ player.match_day_kit_number }}</td>
                      <td>{{ player.name }}</td>
                      <td>{{ player.position }}</td>
                      <td>{{ player.age_group }}</td>
                      <td>{{ player.gameAttendance }}</td>
                      <td>{{ player.cupGameAttendance }}</td>
                      <td>{{ player.trainingAttendance }}</td>
                      <td v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"> <a v-if="player.trialistId === null" href="javascript:;" @click="goToPlayer(player)">View</a></td>
                      <td class="trialist" v-if="player.trialistId !== null"><b-icon icon="person" aria-hidden="true"></b-icon> Trialist</td>
                      <td v-else><b-icon icon="person" aria-hidden="true"></b-icon> First team</td>

                      <!--<td>0</td>-->
                      <td class="red-warning" v-if="player.injury === true">
                        Injured
                      </td>
                      <td class="green-success" v-else><span>fit</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No team memebers</p>
              </div>
            </div>
            <div
              class="team-types"
              v-if="userData.userType === 'Admin' || userData.id === '964'"
            >
            </div>
          </div>
        </div>
        <div
          class="current-week-tab tab-content team-chat-wrapper team-attendance-wrapper"
          v-if="selectedTab === 'league-tab'"
        >
          <!--<div v-html="leagueTable"></div>-->

          <table class="w-a-style">
            <thead>
              <th>Position</th>
              <th>Team</th>
              <th>Played</th>
              <th>Won</th>
              <th>Lost</th>
              <th>Drawn</th>
              <th>Points</th>
            </thead>
            <tbody v-for="team in leagueTeams" :key='team.id'>
              <td>{{team.id }}</td>
              <td>{{team.teamName }}</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tbody>
          </table>
        </div>

        <div
          class="current-week-tab tab-content team-attendance-wrapper team-tab-content"
          v-if="selectedTab === 'team-attendance-tab'"
        >

        <div class="search-filter">
            <div class="form__item">
              <label class="form__label" for="picker">
                Select attendance date range</label
              >
              <date-range-picker
                ref="picker"
                :locale-data="dateRangePickerLocaleData"
                @update="dateRangeSelected()"
                v-model="dateRange"
              >
                <template v-slot:input="picker">
                  <b-icon icon="calendar3"></b-icon>
                  {{ formatMatchDate(picker.startDate) }} -
                  <b-icon icon="calendar3"></b-icon>
                  {{ formatMatchDate(picker.endDate) }}
                </template>
              </date-range-picker>
              <a
                class="clear-daterange"
                v-b-tooltip.hover
                title="Reset date range"
                @click="dateRangeReset()"
                href="javascript:;"
                ><b-icon icon="arrow-counterclockwise"></b-icon
              ></a>
            </div>
          </div>

        <table class="w-a-style attendanceTable" v-if="currentTeam.length > 0 && selectedGamesEvents.length > 0">
          <thead>
            <tr>
              <th>Name</th>
              <th>Attendance average</th>
              <th v-for="game in selectedGamesEvents"
              :key="game.id">
              {{ game.gameType }}<br>
              {{ getDayAndDate(game.matchDate) }}
            </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="teamMember in currentTeam"
              :key="teamMember.id"
            >
              <td>{{ teamMember.name }}</td>
              <td class="text-danger" v-if="getPlayerAverageAttendance(teamMember.id) < 50">{{getPlayerAverageAttendance(teamMember.id)}}% </td>
              <td class="text-success" v-if="getPlayerAverageAttendance(teamMember.id) >= 50">{{getPlayerAverageAttendance(teamMember.id)}}% </td>

              <td v-for="game in selectedGamesEvents"
              :key="game.id">

              <p class="text-success" v-if="checkGameTrainingAttendance(teamMember.id, game.kickOffTime) === 'Attended'">Attended</p>
              <p class="text-warning" v-if="checkGameTrainingAttendance(teamMember.id, game.kickOffTime) === 'No Response'">No Response</p>
              <p class="text-danger" v-if="checkGameTrainingAttendance(teamMember.id, game.kickOffTime) === 'Not Attended'">Not Attended</p>

            </td>
            </tr>
          </tbody>
        </table>
        <div class="empty-state" v-else>
            <img src="../img/emptyStateIllustration.svg" />
            <p>No game and training data available.</p>
          </div>
        </div>

        <div
            class="current-week-tab tab-content team-tab-content"
            v-if="selectedTab === 'homework-tab'"
          >

          <div class="games-info">

            <b-button
                      v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
                      class="main-button"
                      @click="addHomeWorkModalCall()"
                      size="lg"
                      v-b-tooltip.hover
                      title="Add team homework"
                      variant="outline-success"
                      ><b-icon icon="file-text"></b-icon
                    ></b-button>


                    <div v-if="allHomeWorkData.length > 0" class="homework-list">


                    <table class="w-a-style injury-table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>title</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="homework in allHomeWorkData"
                        :key="homework.id"
                      >
                        <td>{{ formatDateForHuman(homework.created_date) }}</td>
                        <td>{{ homework.title }}</td>
                        <td>
                          <div class="player-game-responce">
                            <b-button
                            v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
                              @click="
                                editHomeworkModalCall(homework)
                              "
                              variant="outline-warning"
                              ><b-icon
                                icon="pencil"
                                aria-hidden="true"
                              ></b-icon>
                              Edit</b-button
                            >
                            <b-button
                            v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
                              @click="deleteHomework(homework.id)"
                              variant="outline-danger"
                              ><b-icon
                                icon="trash"
                                aria-hidden="true"
                              ></b-icon>
                              Delete</b-button
                            >

                            <b-button
                              @click="viewHomework(homework)"
                              variant="outline-success"
                              ><b-icon
                                icon="eye"
                                aria-hidden="true"
                              ></b-icon>
                              View</b-button
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    </table>


                    </div>
                    <div v-else class="empty-state">
                    <img src="../img/emptyStateIllustration.svg" />
                    <p>No homework data</p>
                    </div>

            
          </div>
          </div>

        <div
          class="current-week-tab tab-content documents-tab"
          v-if="selectedTab === 'team-documents-tab'"
        >

        <div class="games-info documents-wrapper">
              
              <div
              class="document"
              v-for="document in uploadedDocuments"
              :key="document.id"
              v-b-tooltip.hover
              :title="removeNumberFromStart(document.name)"
            >

            <a :href="`./${document.url}`" target="_blank">
              <img src="../img/documentlib.svg" />
              <h3>{{ removeNumberFromStart(document.name) }}</h3>
            </a>
             
            </div>

            <div v-if="uploadedDocuments.length === 0">
                <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No team documents</p>
                </div>
            </div>
            </div>
          
        </div>

        <div
          class="current-week-tab tab-content team-chat-wrapper"
          v-if="selectedTab === 'team-performance-tab'"
        >

        <ul class="tabs headding-space">
            <li class="tab-item">
              <a
                :class="
                  selectedTabGamesTraining === 'teams-over-games-performance-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTabGamesTraining', 'teams-over-games-performance-tab')"
                href="javascript:;"
                >Games</a
              >
            </li>
            <li class="tab-item">
              <a
                :class="
                  selectedTabGamesTraining === 'teams-over-games-training-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTabGamesTraining', 'teams-over-games-training-tab')"
                href="javascript:;"
                >Training</a
              >
            </li>
          </ul>

          <div class="tabs-wrapper">

            <div
            class="current-week-tab tab-content"
            v-if="selectedTabGamesTraining === 'teams-over-games-performance-tab'"
          >

          <div class="search-filter">
            <div class="form__item">
              <label class="form__label" for="picker">
                Select performance date range</label
              >
              <date-range-picker
                ref="picker"
                :locale-data="dateRangePickerLocaleData"
                @update="performanceDateRangeSelected()"
                v-model="dateRange"
              >
                <template v-slot:input="picker" style="min-width: 350px">
                  <b-icon icon="calendar3"></b-icon>
                  {{ formatMatchDate(picker.startDate) }} -
                  <b-icon icon="calendar3"></b-icon>
                  {{ formatMatchDate(picker.endDate) }}
                </template>
              </date-range-picker>
              <a
                class="clear-daterange"
                v-b-tooltip.hover
                title="Reset date range"
                @click="dateRangeReset()"
                href="javascript:;"
                ><b-icon icon="arrow-counterclockwise"></b-icon
              ></a>

              <div class="checkbox-full-season-wrapper">
                <b-form-checkbox
                  id="checkbox-full-season"
                  v-model="fullSeasonCheckBoxData"
                  @change="fullSeasonCheckBox()"
                  name="checkbox-full-season"
                >
                  Full Season
                </b-form-checkbox>
            </div>
            </div>

          </div>

          <p v-if="pastGames.length > 0" style="width:100%;">Kindly be aware that only games with recorded scores will contribute to the majority of the totals. The "Games Played" category is an exception, as it will include games even without recorded scores.</p>

          <div class="win-loss-draw">
            <span v-for="(pastGame, i) in pastGames" :key="i">
                  <span
                    v-if="pastGame.result_added == '1'"
                    :class="`result-${checkGameResult(pastGame)}`"
                    >{{ checkGameResult(pastGame) }}</span
                  >
                </span>

          </div>

          <div class="past-games-performance" v-if="pastGames.length > 0">

              <div class="main-stats">
                <div class="games-played">
                  <h3>Games played</h3>
                  <span>{{ pastGames.length }}</span>
                </div>

                <div class="games-wins">
                  <h3>Wins</h3>
                  <span>{{ teamStats.wins}}</span>
                </div>

                <div class="games-losses">
                  <h3>Losses</h3>
                  <span>{{ teamStats.losses }}</span>
                </div>

                <div class="games-draws">
                  <h3>Draws</h3>
                  <span>{{ teamStats.draws }}</span>
                </div>

                <div class="games-goals">
                  <h3>Goals</h3>
                  <span>{{ teamStats.goalsScored }}</span>
                </div>

                <div class="games-goals">
                  <h3>Goals Conceded</h3>
                  <span>{{ teamStats.goalsConceded }}</span>
                </div>

                <div class="games-goals">
                  <h3>Clean Sheets</h3>
                  <span>{{ teamStats.cleanSheets }}</span>
                </div>
              </div>

          </div>


          <div class="games-performance-analysis-stats" v-if="gamePerformanceAnalysisData.length > 0">

          <div class="stats-overview">
            <h3>Attacking</h3>

            <div class="performance-bar">
          <p>
          <span
            :class="setBarVariant(avarageGamePerformanceAnalysisData.penetrationScoreTotal)"
            >{{ Math.round(avarageGamePerformanceAnalysisData.penetrationScoreTotal) }}</span
          >
          Penetration
          </p>

          <b-progress
          :value="avarageGamePerformanceAnalysisData.penetrationScoreTotal"
          :variant="setBarVariant(avarageGamePerformanceAnalysisData.penetrationScoreTotal)"
          max="5"
          class="mb-3"
          ></b-progress>
          </div>

          <div class="performance-bar">
          <p>
          <span
            :class="setBarVariant(avarageGamePerformanceAnalysisData.supportScoreTotal)"
            >{{ Math.round(avarageGamePerformanceAnalysisData.supportScoreTotal) }}</span
          >
          Support
          </p>

          <b-progress
          :value="avarageGamePerformanceAnalysisData.supportScoreTotal"
          :variant="setBarVariant(avarageGamePerformanceAnalysisData.supportScoreTotal)"
          max="5"
          class="mb-3"
          ></b-progress>
          </div>

          <div class="performance-bar">
          <p>
          <span
            :class="setBarVariant(avarageGamePerformanceAnalysisData.mobilityScoreTotal)"
            >{{ Math.round(avarageGamePerformanceAnalysisData.mobilityScoreTotal) }}</span
          >
          Mobility
          </p>

          <b-progress
          :value="avarageGamePerformanceAnalysisData.mobilityScoreTotal"
          :variant="setBarVariant(avarageGamePerformanceAnalysisData.mobilityScoreTotal)"
          max="5"
          class="mb-3"
          ></b-progress>
          </div>

          <div class="performance-bar">
          <p>
          <span
            :class="setBarVariant(avarageGamePerformanceAnalysisData.creativityScoreTotal)"
            >{{ Math.round(avarageGamePerformanceAnalysisData.creativityScoreTotal) }}</span
          >
          Creativity
          </p>

          <b-progress
          :value="avarageGamePerformanceAnalysisData.creativityScoreTotal"
          :variant="setBarVariant(avarageGamePerformanceAnalysisData.creativityScoreTotal)"
          max="5"
          class="mb-3"
          ></b-progress>
          </div>

          <div class="performance-bar">
          <p>
          <span
            :class="setBarVariant(avarageGamePerformanceAnalysisData.transitionToAttackScoreTotal)"
            >{{ Math.round(avarageGamePerformanceAnalysisData.transitionToAttackScoreTotal) }}</span
          >
          Transition to Attack
          </p>

          <b-progress
          :value="avarageGamePerformanceAnalysisData.transitionToAttackScoreTotal"
          :variant="setBarVariant(avarageGamePerformanceAnalysisData.transitionToAttackScoreTotal)"
          max="5"
          class="mb-3"
          ></b-progress>
          </div>
          </div>

          <div class="stats-overview">
            <h3>Defending</h3>

            <div class="performance-bar">
          <p>
          <span
            :class="setBarVariant(avarageGamePerformanceAnalysisData.pressureScoreTotal)"
            >{{ Math.round(avarageGamePerformanceAnalysisData.pressureScoreTotal) }}</span
          >
          Pressure
          </p>

          <b-progress
          :value="avarageGamePerformanceAnalysisData.pressureScoreTotal"
          :variant="setBarVariant(avarageGamePerformanceAnalysisData.pressureScoreTotal)"
          max="5"
          class="mb-3"
          ></b-progress>
          </div>

          <div class="performance-bar">
          <p>
          <span
            :class="setBarVariant(avarageGamePerformanceAnalysisData.coverScoreTotal)"
            >{{ Math.round(avarageGamePerformanceAnalysisData.coverScoreTotal) }}</span
          >
          Cover
          </p>

          <b-progress
          :value="avarageGamePerformanceAnalysisData.coverScoreTotal"
          :variant="setBarVariant(avarageGamePerformanceAnalysisData.coverScoreTotal)"
          max="5"
          class="mb-3"
          ></b-progress>
          </div>

          <div class="performance-bar">
          <p>
          <span
            :class="setBarVariant(avarageGamePerformanceAnalysisData.balanceScoreTotal)"
            >{{ Math.round(avarageGamePerformanceAnalysisData.balanceScoreTotal) }}</span
          >
          Balance
          </p>

          <b-progress
          :value="avarageGamePerformanceAnalysisData.balanceScoreTotal"
          :variant="setBarVariant(avarageGamePerformanceAnalysisData.balanceScoreTotal)"
          max="5"
          class="mb-3"
          ></b-progress>
          </div>

          <div class="performance-bar">
          <p>
          <span
            :class="setBarVariant(avarageGamePerformanceAnalysisData.compactnessScoreTotal)"
            >{{ Math.round(avarageGamePerformanceAnalysisData.compactnessScoreTotal) }}</span
          >
          Compactness
          </p>

          <b-progress
          :value="avarageGamePerformanceAnalysisData.compactnessScoreTotal"
          :variant="setBarVariant(avarageGamePerformanceAnalysisData.compactnessScoreTotal)"
          max="5"
          class="mb-3"
          ></b-progress>
          </div>

          <div class="performance-bar">
          <p>
          <span
            :class="setBarVariant(avarageGamePerformanceAnalysisData.transitionToDefendScoreTotal)"
            >{{ Math.round(avarageGamePerformanceAnalysisData.transitionToDefendScoreTotal) }}</span
          >
          Transition to Defend
          </p>

          <b-progress
          :value="avarageGamePerformanceAnalysisData.transitionToDefendScoreTotal"
          :variant="setBarVariant(avarageGamePerformanceAnalysisData.transitionToDefendScoreTotal)"
          max="5"
          class="mb-3"
          ></b-progress>
          </div>
          </div>


          </div>


          <div class="past-games-performance-analysis" v-if="gamePerformanceAnalysisData.length > 0">
          <h4>Game analysis Games</h4>

          <table class="w-a-style">
            <thead>
                    <tr>
                      <th>Date</th>
                      <th>Opposition</th>
                      <th>Coach</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(gamePerformanceAnalysis, index) in gamePerformanceAnalysisData"
                      :key="gamePerformanceAnalysis.id + index"
                    >
                    <td>{{dayMonthYear(gamePerformanceAnalysis.matchDate)}}</td>
                    <td>{{gamePerformanceAnalysis.opposition}}</td>
                    <td>{{gamePerformanceAnalysis.coachName}}</td>
                    <td><a href="javascript:;" @click="gameAnalysisModal(gamePerformanceAnalysis, 'gameAnalysisModal', 'performanceData')">Game analysis</a></td>

                    </tr>
                    </tbody>
          </table>
      
        </div>
        <div v-else>
          <p>You need to add team games or select a data with team games that are avaiable.</p>
        </div>


          </div>

          <div
            class="current-week-tab tab-content"
            v-if="selectedTabGamesTraining === 'teams-over-games-training-tab'"
          >

          <div class="past-games-performance" v-if="pastGames.length > 0">

              <div class="main-stats">
                <div class="games-played">
                  <h3>Training sessions</h3>
                  <span>{{ pastTraining.length }}</span>
                </div>

                <div class="games-wins">
                  <h3>Average attendance</h3>
                  <span>{{ teamStats.wins}}</span>
                </div>

                <div class="games-wins">
                  <h3>Average scores</h3>
                  <span>{{ teamStats.wins}}</span>
                </div>

                <div class="games-wins">
                  <h3>Average effort scores</h3>
                  <span>{{ teamStats.wins}}</span>
                </div>
              </div>

              </div>
            </div>

        </div>


        </div>

      </div>
    </div>
    <b-modal
      id="addEditGames"
      size="lg"
      title="Add new Game"
      :hide-footer="Boolean(true)"
    >
      <form class="form" @submit.prevent="addEditProgrammeSubmit()">
        <div class="form__item">
          <label class="form__label" for="selectSection"
            >Select type of game</label
          >
          <b-form-select
            id="compressureType"
            text="select type"
            v-model="newGameData.gameType"
          >
            <option
              v-for="(i, index) in selectTypeOfGameoptions"
              :key="index"
              :value="i.value"
            >
              {{ i.text }}
            </option>
          </b-form-select>
        </div>
        <div class="form__item">
          <label class="form__label" for="homeTeamSection">Selected team</label>
          <span>{{ newGameData.teamName }}</span>
          <!--<a v-if="showTeamChange === false" href="javascript:;" @click="showTeamChange = true">Change team</a>-->
        </div>
        <div class="form__item" v-if="showTeamChange === true">
          <label class="form__label" for="homeTeamSection">Change team</label>

          <select @change="setSelectedTeam($event)" id="userSelect">
            <option disabled value="">Please select a team</option>
            <option
              v-for="(clubTeam, index) in clubTeams"
              :value="clubTeam.teamName"
              :key="clubTeam.id + index"
            >
              {{ clubTeam.teamName }}
            </option>
          </select>
        </div>

        <div v-if="newGameData.gameType !== 'Training'" class="form__item">
          <label
            v-if="
              leagueTeams.length > 0 && newGameData.gameType === 'League game'
            "
            class="form__label"
            for="competitionSection"
            >Select opposition team</label
          >

          <label v-else class="form__label" for="competitionSection"
            >Add opposition team</label
          >

          <select
            v-if="leagueTeams.length > 0 && newGameData.gameType === 'League game'"
            @change="populateSelectedTeamVenue($event, 'newGameData')"
            id="competitionSection"
          >
            <option disabled value="">Please select an opposition team</option>
            <option
              v-for="team in leagueTeams"
              v-bind:key="team.id"
              :value="team.id"
            >
              {{ team.teamName }}
            </option>
          </select>
          <input
            v-else
            id="competitionSection"
            type="text"
            v-model="newGameData.opposition"
            name="competitionSection"
          />
        </div>
        <div class="form__item">
          <label class="form__label" for="matchDate">
            <span>{{ newGameData.gameType }}</span>
            Date</label
          >
          <datepicker
            wrapper-class="fullscreen-when-on-mobile"
            :disabled-dates="disabledDates"
            v-model="newGameData.matchDate"
            name="matchDate"
            id="matchDate"
          />
        </div>
        <div class="form__item">
          <label class="form__label" for="kickOff">
            <span v-if="newGameData.gameType === 'Training'">Start</span>
            <span v-else>Kick off</span>
            time</label
          >
          <input
            id="kickOff"
            type="time"
            min="06:00"
            max="23:00"
            v-model="newGameData.kickOffTime"
            name="kickOff"
          />
        </div>
        <div class="form__item" v-if="newGameData.gameType === 'Training'">
          <label class="form__label" for="endTime">End time</label>
          <input
            id="endTime"
            type="time"
            min="06:00"
            max="23:00"
            v-model="newGameData.endTime"
            name="endTime"
          />
        </div>

        <PitchSelect
          :pitches="pitches"
          @pitchChange="pitchChange"
          @default-pitch-inputs="defaultPitchInputs"
          :gameData="newGameData"
        />

        <template v-if="togglePitchDetails === false">
          <div class="form__item">
            <label class="form__label" for="location">Location name</label>
            <input
              id="location"
              type="text"
              v-model="newGameData.locationName"
              name="location"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="address">Location address</label>
            <input
              id="address"
              type="text"
              v-model="newGameData.address"
              name="address"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="postCode">Location postCode</label>
            <input
              id="postCode"
              type="text"
              v-model="newGameData.postCode"
              name="postCode"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="selectpitchType"
              >Select Pitch type</label
            >
            <select v-model="newGameData.pitchType" id="selectpitchType">
              <option disabled value="">Please select a pitch type</option>
              <option value="4G">4G</option>
              <option value="3G">3G</option>
              <option value="Grass">Grass</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </template>

        <template v-else>
          <div class="form__item">
            <label class="form__label" for="location">Location name</label>
            <input
              id="location"
              type="text"
              v-model="newGameData.locationName"
              name="location"
              disabled="true"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="address">Location address</label>
            <input
              id="address"
              type="text"
              v-model="newGameData.address"
              name="address"
              disabled="true"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="postCode">Location postCode</label>
            <input
              id="postCode"
              type="text"
              v-model="newGameData.postCode"
              name="postCode"
              disabled="true"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="selectpitchType"
              >Select Pitch type</label
            >
            <input
              id="selectpitchType"
              type="text"
              v-model="newGameData.pitchType"
              name="selectpitchType"
              disabled="true"
            />
          </div>
        </template>

        <div class="form__item" v-if="newGameData.gameType !== 'Training'">
          <label class="form__label" for="stripType">Strip type</label>
          <input
            id="stripType"
            type="text"
            v-model="newGameData.stripType"
            name="stripType"
          />
        </div>
        <div class="form__item" v-if="newGameData.gameType !== 'Training'">
          <label class="form__label" for="meetTime">Meet time</label>
          <input
            id="meetTime"
            type="time"
            min="06:00"
            max="23:00"
            v-model="newGameData.meetTime"
            name="meetTime"
          />
        </div>
        <div class="form__item">
          <label class="form__label" for="notes">Notes</label>
          <textarea
            id="notes"
            type="text"
            v-model="newGameData.notes"
            name="notes"
          ></textarea>
        </div>

       <div v-if="newGameData.gameType === 'Training'" class="form__item recurring-event-checkBox-class">
          <b-form-checkbox
            id="recurring-event-checkBox"
            v-model="recurringEvent"
            name="recurring-event-checkBox"
            @change="toggleRecurringEvent()"
          >
            <span class="check-box-text">Make event recurring</span>
          </b-form-checkbox>
        </div>

        <div class="form__item" v-if="recurringEvent === true">
          <label class="form__label" for="recurringEventSelect"
            >Select recurring type</label
          >
          <select
            v-model="newGameData.recurringEventType"
            id="recurringEventSelect"
          >
            <option disabled value="">
              Please select a recurring event type
            </option>
            <option value="7">Weekly</option>
            <option value="14">By weekly</option>
          </select>
        </div>

        <div class="form__item">
          <button type="submit" class="green-button">Add game</button>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="editGames"
      size="lg"
      title="Edit Game"
      :hide-footer="Boolean(true)"
    >
      <form class="form" @submit.prevent="editProgrammeSubmit('editGames')">
        <!--<div class="form__item">
            <label class="form__label" for="selectSection"
              >Select type of game</label>
            <b-form-select id="compressureType" text="select type" v-model="editGame.gameType">
               <option value="null" disabled>'Please select a game type</option>
               <option v-for="(i, index) in selectTypeOfGameoptions" :key="index" :value="i.value">{{ i.text }}</option>
            </b-form-select>
          </div>
          <div class="form__item">
            <label class="form__label" for="homeTeamSection">Selected team</label>
            <span>{{ editGame.teamName }}</span>
            <a v-if="showTeamChange === false" href="javascript:;" @click="showTeamChange = true">Change team</a>

          </div>-->
        <!--<div class="form__item" v-if="showTeamChange === true">
            <label class="form__label" for="homeTeamSection">Change team</label>

            <select @change="setSelectedTeam($event)" id="userSelect">
              <option disabled value="">Please select a team</option>
              <option v-for="clubTeam in clubTeams" :value="clubTeam.teamName" :key="clubTeam.id">{{ clubTeam.teamName }}</option>
            </select>

          </div>-->
        <div v-if="editGame.gameType !== 'Training'" class="form__item">
          <label
            v-if="leagueTeams.length > 0"
            class="form__label"
            for="competitionSection"
            >Select opposition team</label
          >

          <label v-else class="form__label" for="competitionSection"
            >Add opposition team</label
          >

          <select
            v-if="leagueTeams.length > 0 && editGame.gameType !== 'Cup game'"
            id="competitionSection"
            @change="populateSelectedTeamVenue($event,'editGame')"
          >
            <option disabled value="">Please select an opposition team</option>
            <option
              v-for="team in leagueTeams"
              v-bind:key="team.id"
              :value="team.id"
            >
              {{ team.teamName }}
            </option>
          </select>
          <input
            v-else
            id="competitionSection"
            type="text"
            v-model="editGame.opposition"
            name="competitionSection"
          />
        </div>
        <div class="form__item">
          <label class="form__label" for="matchDate">
            <span>{{ selectedGame.gameType }}</span>
            Date</label
          >
          <datepicker
            wrapper-class="fullscreen-when-on-mobile"
            :disabled-dates="disabledDates"
            v-model="editGame.matchDate"
            name="matchDate"
            id="matchDate"
          />
        </div>
        <div class="form__item">
          <label class="form__label" for="kickOff">
            <span v-if="editGame.gameType === 'Training'">Start</span>
            <span v-else>Kick off</span>
            time</label
          >
          <input
            id="kickOff"
            type="time"
            min="06:00"
            max="23:00"
            v-model="editGame.kickOffTime"
            name="kickOff"
          />
        </div>
        <div class="form__item" v-if="editGame.gameType === 'Training'">
          <label class="form__label" for="endTime">End time</label>
          <input
            id="endTime"
            type="time"
            min="06:00"
            max="23:00"
            v-model="editGame.endTime"
            name="endTime"
          />
        </div>

        <PitchSelect
          :pitches="pitches"
          @pitchChange="pitchChange"
          @default-pitch-inputs="defaultPitchInputs"
          :gameData="editGame"
        />


        <div class="form__item">
            <label class="form__label" for="location">Pitch booking type</label>
            {{ setPitchZoneText(editGame) }}
          </div>

        <template v-if="togglePitchDetails === false">
          <div class="form__item">
            <label class="form__label" for="location">Location name</label>
            <input
              id="location"
              type="text"
              v-model="editGame.locationName"
              name="location"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="address">Location address</label>
            <input
              id="address"
              type="text"
              v-model="editGame.address"
              name="address"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="postCode">Location postCode</label>
            <input
              id="postCode"
              type="text"
              maxlength="6"
              v-model="editGame.postCode"
              name="postCode"
            />
          </div>

          <div class="form__item">
            <label class="form__label" for="selectpitchType"
              >Select Pitch type</label
            >
            <select v-model="editGame.pitchType" id="selectpitchType">
              <option disabled value="">Please select a pitch type</option>
              <option value="4G">4G</option>
              <option value="3G">3G</option>
              <option value="Grass">Grass</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </template>

        <template v-else>
          <div class="form__item">
            <label class="form__label" for="location">Location name</label>
            <input
              id="location"
              type="text"
              v-model="editGame.locationName"
              name="location"
              disabled="true"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="address">Location address</label>
            <input
              id="address"
              type="text"
              v-model="editGame.address"
              name="address"
              disabled="true"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="postCode">Location postCode</label>
            <input
              id="postCode"
              type="text"
              maxlength="6"
              v-model="editGame.postCode"
              name="postCode"
              disabled="true"
            />
          </div>

          <div class="form__item">
            <label class="form__label" for="selectpitchType"
              >Select Pitch type</label
            >
            <input
              id="selectpitchType"
              type="text"
              v-model="editGame.pitchType"
              name="selectpitchType"
              disabled="true"
            />
          </div>
        </template>
        <div class="form__item" v-if="editGame.gameType !== 'Training'">
          <label class="form__label" for="stripType">Strip type</label>
          <input
            id="stripType"
            type="text"
            v-model="editGame.stripType"
            name="stripType"
          />
        </div>
        <div class="form__item" v-if="editGame.gameType !== 'Training'">
          <label class="form__label" for="meetTime">Meet time</label>
          <input
            id="meetTime"
            type="time"
            min="06:00"
            max="23:00"
            v-model="editGame.meetTime"
            name="meetTime"
          />
        </div>
        <div class="form__item">
          <label class="form__label" for="notes">Notes</label>
          <textarea
            id="notes"
            type="text"
            v-model="editGame.notes"
            name="notes"
          ></textarea>
        </div>

        <!--<div v-if="newGameData.gameType === 'Training'" class="form__item recurring-event-checkBox-class">
          <b-form-checkbox
            id="recurring-event-checkBox"
            v-model="recurringEvent"
            name="recurring-event-checkBox"
            @change="toggleRecurringEvent()"
          >
            <span class="check-box-text">Make event recurring</span>
          </b-form-checkbox>
        </div>

        <div class="form__item" v-if="editGame.recurringEventType !== null">
          <label class="form__label" for="recurringEventSelect"
            >Select recurring type</label
          >
          <select
            v-model="editGame.recurringEventType"
            id="recurringEventSelect"
          >
            <option disabled value="">
              Please select a recurring event type
            </option>
            <option value="7">Weekly</option>
            <option value="14">By weekly</option>
          </select>
        </div>-->

        <div v-if="editGame.result_added == '1'" class="game-results-wrapper">
          <div class="form__item">
            <label class="form__label" for="notes">Home team goals</label>
            <textarea
              id="home_team_goals"
              type="number"
              v-model="editGame.home_team_goals"
              name="home_team_goals"
            ></textarea>
          </div>

          <div class="form__item">
            <label class="form__label" for="notes"
              >Home team goal scorers</label
            >
            <textarea
              id="home_team_goal_scorers"
              type="text"
              v-model="editGame.home_team_goal_scorers"
              name="home_team_goal_scorers"
            ></textarea>
          </div>

          <div class="form__item">
            <label class="form__label" for="notes">Away team goals</label>
            <textarea
              id="away_team_goals"
              type="number"
              v-model="editGame.away_team_goals"
              name="away_team_goals"
            ></textarea>
          </div>

          <div class="form__item">
            <label class="form__label" for="notes"
              >Away team goal scorers</label
            >
            <textarea
              id="away_team_goal_scorers"
              type="text"
              v-model="editGame.away_team_goal_scorers"
              name="away_team_goal_scorers"
            ></textarea>
          </div>
        </div>

        <div class="form__item">
          <b-button type="submit" variant="success">Save changes</b-button>
        </div>
      </form>
    </b-modal>
    <b-modal id="viewGameDetails" size="lg" :hide-footer="Boolean(true)">
      <h2 class="small-title">
        {{ selectedGame.gameType }}
        <span v-if="selectedGame.gameType !== 'Training'"
          >V {{ selectedGame.opposition }}</span
        > - <span class="small-text">{{ setPitchZoneText(selectedGame) }}</span>
      </h2>
      <p>
        <span v-if="selectedGame.gameType !== 'Training'"
          ><b-icon icon="calendar2-day" aria-hidden="true"></b-icon> Kick
          off:</span
        >
        <b-icon
          v-if="selectedGame.gameType === 'Training'"
          icon="alarm"
          aria-hidden="true"
        ></b-icon>
        {{ getDayAndDate(selectedGame.matchDate) }}
        <span v-if="selectedGame.gameType !== 'Training'"> at </span
        >{{ formatISOStringDateAndTime(selectedGame.kickOffTime) }}
        <span v-if="selectedGame.gameType === 'Training'"
          >- {{ formatISOStringDateAndTime(selectedGame.endTime) }}</span
        ><br />
        <span v-if="selectedGame.gameType !== 'Training'"
          ><b-icon icon="alarm" aria-hidden="true"></b-icon> Report for:
          {{ formatISOStringDateAndTime(selectedGame.meetTime) }}</span
        >
      </p>

      <span class="venue-text"
        ><b-icon icon="map" aria-hidden="true"></b-icon> Venue:
        {{ selectedGame.address }} {{ selectedGame.postCode }}</span
      >
      <p class="padding-left">
        {{ selectedGame.locationName }} -
        <a
          class="map-link"
          v-if="selectedGame.postCode !== ''"
          :href="`https://maps.google.fr/maps?q=${selectedGame.postCode}`"
          target="_blank"
          ><b-icon icon="pin-map" aria-hidden="true"></b-icon> Map</a
        >
      </p>
      <p>
        <b-icon icon="grid3x3 " aria-hidden="true"></b-icon> Pitch type:
        {{ selectedGame.pitchType }}
      </p>
      <p v-if="selectedGame.gameType !== 'Training'">
        <b-icon icon="person" aria-hidden="true"></b-icon> Strip:
        {{ selectedGame.stripType }}
      </p>
      <p v-if="selectedGame.notes != ''">
        <b-icon icon="chat-right-text" aria-hidden="true"></b-icon>
        {{ selectedGame.notes }}
      </p>

      <div
        v-if="checkUserIsAttendingGame() === false"
        class="player-game-responce"
      >
        <b-button 
        v-if="userData.userType !== 'Admin' && userData.userType !== 'Coach'"
        @click="gameResponce('Yes')" variant="outline-success"
          ><b-icon icon="hand-thumbs-up" aria-hidden="true"></b-icon> Yes
          going</b-button
        >
        <b-button v-if="userData.userType !== 'Admin' && userData.userType !== 'Coach'"
        @click="gameResponce('No')" variant="outline-danger"
          ><b-icon icon="hand-thumbs-down" aria-hidden="true"></b-icon> No can't
          make it</b-button
        >
      </div>
      <div v-else class="player-game-responce">
        <b-button disabled variant="success"
          ><b-icon icon="hand-thumbs-up" aria-hidden="true"></b-icon>
          Attending</b-button
        >
        <b-button v-if="userData.userType !== 'Admin' && userData.userType !== 'Coach'"
        @click="gameResponce('No')" variant="outline-danger"
          ><b-icon icon="hand-thumbs-down" aria-hidden="true"></b-icon> No can't
          make it</b-button
        >
        <p class="attending-game">
          <b-icon icon="hand-thumbs-up" aria-hidden="true"> </b-icon> You are
          currenlty attending this
          <span v-if="selectedGame.gameType === 'Training'">Session</span>
          <span v-else>Game</span>
        </p>
      </div>

      <div class="player-game-numbers">
        <ul class="tabs">
          <li class="tab-item">
            <a
              :class="
                playerAvaiabilityTab === 'going-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('playerAvaiabilityTab', 'going-tab')"
              href="javascript:;"
              >Going</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                playerAvaiabilityTab === 'not-going-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('playerAvaiabilityTab', 'not-going-tab')"
              href="javascript:;"
              >Not Going</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                playerAvaiabilityTab === 'no-response-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('playerAvaiabilityTab', 'no-response-tab')"
              href="javascript:;"
              >No Response</a
            >
          </li>

          <li class="tab-item">
            <a
              :class="
                playerAvaiabilityTab === 'injured-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('playerAvaiabilityTab', 'injured-tab')"
              href="javascript:;"
              >Injured</a
            >
          </li>
        </ul>

        <div class="tabs-wrapper">
          <div class="tab-content" v-if="playerAvaiabilityTab === 'going-tab'">
            <ul>
              <li
                v-for="(attendee, index) in selectedGameAttendees"
                :key="index"
              >
                <p v-if="attendee.going == '1'">
                  {{ attendee.name }}
                  <span class="going"
                    ><b-icon icon="hand-thumbs-up" aria-hidden="true"></b-icon
                  ></span>

                  <span v-if="userData.userType === 'Admin' || userData.userType === 'Coach'">
                    <b-form-select v-model="gameResponceValueChange" @change="gameResponceChange(attendee.user_id)" class="mb-3">
                      <b-form-select-option :value="null">Change player response</b-form-select-option>
                      <b-form-select-option value="No" >Not Going</b-form-select-option>
                    </b-form-select>
                  </span>
                </p>
              </li>
            </ul>
          </div>
          <div
            class="tab-content"
            v-if="playerAvaiabilityTab === 'not-going-tab'"
          >
            <ul>
              <li
                v-for="(attendee, index) in selectedGameAttendees"
                :key="index"
              >
                <p v-if="attendee.notGoing == '1'">
                  {{ attendee.name }}
                  <span class="not-going"
                    ><b-icon icon="hand-thumbs-down" aria-hidden="true"></b-icon
                  ></span>

                  <span v-if="userData.userType === 'Admin' || userData.userType === 'Coach'">
                    <b-form-select v-model="gameResponceValueChange" @change="gameResponceChange(attendee.user_id)" class="mb-3">
                      <b-form-select-option :value="null">Change player response</b-form-select-option>
                      <b-form-select-option value="Yes">Going</b-form-select-option>
                    </b-form-select>
                  </span>
                </p>
              </li>
            </ul>
          </div>

          <div
            class="tab-content"
            v-if="playerAvaiabilityTab === 'no-response-tab'"
          >
            <ul>
              <li
                v-for="(attendee, index) in noResponselayersList"
                :key="index"
              >
                <p>
                  {{ attendee.name }}
                  <span v-if="userData.userType === 'Admin' || userData.userType === 'Coach'">
                    <b-form-select v-model="gameResponceValueChange" @change="gameResponceChange(attendee.id)" class="mb-3">
                      <b-form-select-option :value="null">Change player response</b-form-select-option>
                      <b-form-select-option value="No" >Not Going</b-form-select-option>
                      <b-form-select-option value="Yes">Going</b-form-select-option>
                    </b-form-select>
                  </span>
                </p>
              </li>
            </ul>
          </div>

          <div
            class="tab-content"
            v-if="playerAvaiabilityTab === 'injured-tab'"
          >
            <ul>
              <li v-for="(attendee, index) in injuredPlayersList" :key="index">
                <p>
                  {{ attendee.name }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="viewGameDeleteEditOptions"
      @hidden="occuranceModalClose()"
      size="lg"
      :hide-footer="Boolean(true)"
    >
      <h2>
        {{ modalTypeNmae }} {{ selectedGame.teamName }}
        <span v-if="selectedGame.gameType !== 'Training'"
          >V {{ selectedGame.opposition }}</span
        >
        <span v-else>{{ selectedGame.gameType }}</span>
      </h2>
      <b-form-group
        id="occurance-radios"
        :label="
          modalTypeNmae === 'Edit'
            ? 'Select the occurance you wish to Edit'
            : 'Select the occurance you wish to Delete'
        "
      >
        <b-form-radio
          v-model="gameDeleteEditoccuranceValue"
          name="game-delete-radios"
          :value="`one-${modalTypeNmae}`"
        >
          Only this occurance</b-form-radio
        >
        <!--<b-form-radio
          v-model="gameDeleteEditoccuranceValue"
          name="game-delete-radios"
          :value="`all-${modalTypeNmae}`"
        >
          All occurances</b-form-radio
        >
        <b-form-invalid-feedback v-if="gameDeleteEditoccuranceValue === null"
          >Please select one</b-form-invalid-feedback
        >-->
      </b-form-group>

      <div class="player-game-responce">
        <b-button
          @click="deleteGames()"
          v-if="
            modalTypeNmae === 'Delete' && gameDeleteEditoccuranceValue !== null
          "
          variant="outline-danger"
          ><b-icon icon="trash" aria-hidden="true"></b-icon> Delete</b-button
        >
        <b-button
          @click="showEditAddGamesModal('edit')"
          v-if="
            modalTypeNmae === 'Edit' && gameDeleteEditoccuranceValue !== null
          "
          variant="outline-warning"
          v-b-tooltip.hover
          title="Edit"
          ><b-icon icon="pencil" aria-hidden="true"></b-icon
        ></b-button>
      </div>
    </b-modal>

    <b-modal
      id="addNewGameresultModal"
      size="lg"
      :hide-footer="Boolean(true)"
      @close="resetGameResultsGoalSelection"
    >
      <h4>
        {{ editGame.gameType }} v {{ editGame.opposition }} -
        {{ dayMonthYear(editGame.kickOffTime) }}
        {{ formatISOStringDateAndTime(editGame.kickOffTime) }}
      </h4>

      <form
        class="form"
        @submit.prevent="editProgrammeSubmit('addNewGameresultModal')"
      >
        <div class="form__item">
          <label class="form__label" for="selectGameEvent"
            >Select game event</label
          >
          <select id="selectGameEvent" v-model="gameEventSelected">
            <option disabled value="">Please select a game event</option>
            <option value="goal">Add goal scorer & goal</option>
            <option value="result">Only add full result</option>
            <!--<option value="booking">Booking</option>
            <option value="sendingOff">Sending off</option>-->
          </select>
        </div>

        <template v-if="gameEventSelected === 'goal'">
          <div class="form__item">
            <label class="form__label" for="selectGameEvent"
              >What team scored
            </label>
            <select
              id="selectGameEvent"
              v-model="teamWhoScored"
              @change="updateTeamWhoScored()"
            >
              <option disabled value="">Please select a team</option>
              <option value="home">Home</option>
              <option value="away">Away</option>
            </select>
          </div>

          <div class="form__item">
            <label class="form__label" for="goal_type">Goal type </label>
            <select id="goal_type" v-model="editGame.goal_type">
              <option disabled value="">Please select the goal type</option>
              <option value="Open play">Open play</option>
              <option value="Fee kick">Fee kick</option>
              <option value="Pentaly">Pentaly</option>
            </select>
          </div>

          <div class="form__item" v-if="teamWhoScored === 'home'">
            <label class="form__label" for="userSelect">Who scored</label>

            <div class="user-select-wrapper">
              <div v-for="(user, index) in currentTeam" :key="user.id + index">
                <input
                  @change="goalScoredUserIdAddRemove($event)"
                  type="checkbox"
                  :id="user.id"
                  :name="user.id"
                  :value="user.name"
                  :checked="user.selected"
                />
                <label :for="user.id">{{ user.name }}</label
                ><br />
              </div>
            </div>
          </div>

          <div class="form__item" v-if="teamWhoScored === 'home'">
            <label class="form__label" for="userSelect"
              >Who assisted the goal</label
            >

            <div class="user-select-wrapper">
              <div v-for="(user, index) in currentTeam" :key="user.id + index">
                <input
                  @change="goalAssistUserIdAddRemove($event)"
                  type="checkbox"
                  :id="user.id"
                  :name="user.id"
                  :value="user.name"
                  :checked="user.selected"
                />
                <label :for="user.id">{{ user.name }}</label
                ><br />
              </div>
            </div>
          </div>

          <div class="form__item">
            <label class="form__label" for="selectGameEvent"
              >Time of goal (mins)
            </label>
            <input
              class="form__input very-small-two-numbers"
              type="number"
              min="0"
              max="99"
              size="1"
              maxlength="2"
              id="timeOfGoal"
              v-model="editGame.goal_time"
            />
          </div>
        </template>
        <template v-if="gameEventSelected === 'result'">
          <div class="form__item">
            <label class="form__label" for="home_team_goals"
              >Home team goals
            </label>
            <input
              class="form__input very-small-two-numbers"
              type="number"
              min="0"
              max="99"
              size="1"
              maxlength="2"
              id="home_team_goals"
              v-model="editGame.home_team_goals"
            />
          </div>

          <div class="form__item">
            <label class="form__label" for="away_team_goals"
              >Away team goals
            </label>
            <input
              class="form__input very-small-two-numbers"
              type="number"
              min="0"
              max="99"
              size="1"
              maxlength="2"
              id="away_team_goals"
              v-model="editGame.away_team_goals"
            />
          </div>
        </template>

        <div class="player-game-responce">
          <b-button type="submit" variant="success">Add goal</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="gameAnalysisModal"
      size="lg"
      :hide-footer="Boolean(true)"
      title="Game Analysis"
    >
      <h4>
        {{ selectedGameAnalysisData.teamName }} vs
        {{ selectedGameAnalysisData.opposition }}
      </h4>


      <div class="game-analysis-details">
      <p>Coach: {{ selectedGameAnalysisData.coachName }}</p>
      <p>Match date: {{ getDayAndDate(selectedGameAnalysisData.matchDate) }}</p>
    </div>

      

        <div class="attacting-options">
          <h4>Attacking Observations</h4>

          <div class="form__item">
            <label class="form__label" for="penetrationComments"
              >Penetration
            </label>
            <div class="ratingStars">
                <star-rating
                  v-model="selectedGameAnalysisData.penetrationScore"
                ></star-rating>
              </div>
            <b-form-textarea
              placeholder="Comments"
              rows="3"
              max-rows="6"
              id="penetrationComments"
              v-model="selectedGameAnalysisData.penetrationComments"
            ></b-form-textarea>
          </div>

          <div class="form__item">
            <label class="form__label" for="supportComments"
              >Support
            </label>
            <div class="ratingStars">
                <star-rating
                  v-model="selectedGameAnalysisData.supportScore"
                ></star-rating>
              </div>
            <b-form-textarea
              placeholder="Comments"
              rows="3"
              max-rows="6"
              id="supportComments"
              v-model="selectedGameAnalysisData.supportComments"
            ></b-form-textarea>
          </div>

          <div class="form__item">
            <label class="form__label" for="mobilitComments"
              >Mobility
            </label>
            <div class="ratingStars">
                <star-rating
                  v-model="selectedGameAnalysisData.mobilityScore"
                ></star-rating>
              </div>
            <b-form-textarea
              placeholder="Comments"
              rows="3"
              max-rows="6"
              id="mobilitComments"
              v-model="selectedGameAnalysisData.mobilityComments"
            ></b-form-textarea>
          </div>

          <div class="form__item">
            <label class="form__label" for="creativitComments"
              >Creativity
            </label>
            <div class="ratingStars">
                <star-rating
                  v-model="selectedGameAnalysisData.creativityScore"
                ></star-rating>
              </div>
            <b-form-textarea
              placeholder="Comments"
              rows="3"
              max-rows="6"
              id="creativitComments"
              v-model="selectedGameAnalysisData.creativityComments"
            ></b-form-textarea>
          </div>

          <div class="form__item">
            <label class="form__label" for="creativitComments"
              >Transition to Attack
            </label>
            <div class="ratingStars">
                <star-rating
                  v-model="selectedGameAnalysisData.transitionToAttackScore"
                ></star-rating>
              </div>
            <b-form-textarea
              placeholder="Comments"
              rows="3"
              max-rows="6"
              id="transitionToAttackComments"
              v-model="selectedGameAnalysisData.transitionToAttackComments"
            ></b-form-textarea>
          </div>
        </div>


        <div class="defending-options">

            <h4>Defending Observations</h4>

            <div class="form__item">
              <label class="form__label" for="pressureComments"
                >Pressure
              </label>
              <div class="ratingStars">
                  <star-rating
                    v-model="selectedGameAnalysisData.pressureScore"
                  ></star-rating>
                </div>
              <b-form-textarea
                placeholder="Comments"
                rows="3"
                max-rows="6"
                id="pressureComments"
                v-model="selectedGameAnalysisData.pressureComments"
              ></b-form-textarea>
          </div>

          <div class="form__item">
            <label class="form__label" for="supportComments"
              >Cover
            </label>
            <div class="ratingStars">
                <star-rating
                  v-model="selectedGameAnalysisData.coverScore"
                ></star-rating>
              </div>
            <b-form-textarea
              placeholder="Comments"
              rows="3"
              max-rows="6"
              id="coverComments"
              v-model="selectedGameAnalysisData.coverComments"
            ></b-form-textarea>
          </div>


          <div class="form__item">
            <label class="form__label" for="balanceComments"
              >Balance
            </label>
            <div class="ratingStars">
                <star-rating
                  v-model="selectedGameAnalysisData.balanceScore"
                ></star-rating>
              </div>
            <b-form-textarea
              placeholder="Comments"
              rows="3"
              max-rows="6"
              id="balanceComments"
              v-model="selectedGameAnalysisData.balanceComments"
            ></b-form-textarea>
          </div>

          <div class="form__item">
            <label class="form__label" for="compactnessComments"
              >Compactness
            </label>
            <div class="ratingStars">
                <star-rating
                  v-model="selectedGameAnalysisData.compactnessScore"
                ></star-rating>
              </div>
            <b-form-textarea
              placeholder="Comments"
              rows="3"
              max-rows="6"
              id="compactnessComments"
              v-model="selectedGameAnalysisData.compactnessComments"
            ></b-form-textarea>
          </div>

          <div class="form__item">
            <label class="form__label" for="transitionToDefendComments"
              >Transition to Defend
            </label>
            <div class="ratingStars">
                <star-rating
                  v-model="selectedGameAnalysisData.transitionToDefendScore"
                ></star-rating>
              </div>
            <b-form-textarea
              placeholder="Comments"
              rows="3"
              max-rows="6"
              id="transitionToDefendComments"
              v-model="selectedGameAnalysisData.transitionToDefendComments"
            ></b-form-textarea>
          </div>
          </div>

          <div class="general-options">
            <h4>General Observations</h4>
            <wysiwyg
                  v-model="selectedGameAnalysisData.generalComments" />

          </div>

          <div class="training-suggestions">
            <h4>Training Suggestions</h4>
            <wysiwyg
                  v-model="selectedGameAnalysisData.trainingComments" />

          </div>

          <div class="player-game-responce">
          <b-button v-if="selectedGameAnalysisData.edit === true" @click="upDateGameAnalysisData()" variant="success">Edit</b-button>
          <b-button v-else @click="createNewGameAnalysis()" variant="success">Save</b-button>
        </div>
    </b-modal>

    <b-modal
      id="chatDetailsModal"
      size="lg"
      :hide-footer="Boolean(true)"
      :title="selectedChat.name"
      :static= true
      @close="detachListener"
    >

    <div class="chat-messages">
      <div v-for="message in selectedChatMessages" :class = "(message.userId == userData.id) ? 'chat-details receiver' : 'chat-details'" :key="message.id">

        <img
          v-if="
            message.userImageURL !== '' &&
            message.userImageURL !== null
          "
          :src="getImgUrl(message.userImageURL)"
          alt=""
        />
        <img v-else :src="getImgUrl('default-image.png')" alt="" />
        <div class="chat-wrapper">
        <span class="chat-name-time-wrapper">

          <span class="chat-name">{{message.name}}</span><span class="chat-time">- {{ getTimeFromDate(message.createdDate) }}</span>

        </span>
        <span class="chat-message">{{message.messageContent}}</span>
      </div>
      </div>

      <div class="empty-state" v-if="selectedChatMessages.length === 0">
            <img src="../img/emptyStateIllustration.svg" />
            <p>No messages...</p>
          </div>

      <div ref="bottom" ></div>
    </div>

    <div class="message-input">
        <div class="form__item">
            <input
              class="form__input"
              type="text"
              placeholder="Type a message..."
              v-model="selectedChat.text"
            />
            <b-button @click="saveChatMessage()" variant="success">
              <b-icon icon="arrow-right-circle" aria-hidden="true"></b-icon
                  >
            </b-button>
          </div>
          

      </div>
  </b-modal>

  <b-modal
      id="chatGroupModal"
      size="lg"
      :hide-footer="Boolean(true)"
      title="Chat group"
    >

    <div class="form__item">
      <input
        class="form__input full-size"
        type="text"
        placeholder="Name of group"
        v-model="chatGroupName"
      />
      <p v-if="chatGroupName === null">Please add a chat group name.</p>
    </div>

    <div>
      <input
        @change="selectedAllPlayerToggle()"
        type="checkbox"
        id="selectedAllTeamsToggle"
        name="selectedAllTeamsToggle"
        v-model="selectedAllPlayerToggleValue"
      />
      <label for="selectedAllTeamsToggle">Select all</label
      ><br />
          </div>

        <div class="user-select-wrapper">
          <div class="player-list" v-for="player in currentTeam" :key="player.id">
            <label :for="player.id">
              <img
                v-if="
                  player.userImage !== '' &&
                  player.userImage !== null
                "
                id="user_photo"
                :src="getImgUrl(player.userImage)"
                alt=""
              />
              <img v-else :src="getImgUrl('default-image.png')" alt="" />
              {{ player.name }}
            </label>
            <input
              type="checkbox"
              :id="player.id"
              :name="player.id"
              :value="player.id"
              v-model="listOfSelectedplayerIds"
            />
            </div>
          </div>

          <button
        @click="saveNewChatGroup()"
        class="green-button add-new-programme"
      >
      Add chat group</button>

  </b-modal>

  <b-modal
        id="addHomeworkModal"
        size="lg"
        :hide-footer="Boolean(true)"
      >

      <h2><span v-if="newHomeWorkData.id !== undefined">Edit</span><span v-else>Add</span> homework</h2>

      <div class="form__item">
        <label class="form__label" for="goals"
          >Title</label
        >
        <b-form-input
          id="textarea"
          v-model="newHomeWorkData.title"
        ></b-form-input>
      </div>

      <div class="form__item">
        <label class="form__label" for="goals"
          >Information</label
        >
        <wysiwyg v-model="newHomeWorkData.information" />
      </div>

          <b-button  v-if="newHomeWorkData.id !== undefined" @click="editHomework()" size="lg" variant="outline-success"
            ><b-icon icon="file-text" aria-hidden="true"></b-icon> Edit
            game homework</b-button
          >

          <b-button v-else @click="saveHomework()" size="lg" variant="outline-success"
            ><b-icon icon="file-text" aria-hidden="true"></b-icon> Submit
            game homework</b-button
          >
      </b-modal>

      <b-modal
              id="viewHomeworkModal"
              size="lg"
              :hide-footer="Boolean(true)"
            >

            <h2>homework</h2>

            <div class="form__item">
              <label class="form__label" for="goals"
                >Title</label
              >
              <p>{{newHomeWorkData.title}}</p>
            </div>

            <div class="form__item">
              <label class="form__label" for="goals"
                >Date</label
              >
              <p>{{formatDate(newHomeWorkData.matchDate)}}</p>
            </div>

            <div class="form__item">
              <label class="form__label" for="goals"
                >Information</label
              >
              <p v-html="newHomeWorkData.information"></p>
            </div>
            </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { CHECK_PROFILE_HAS_DATA, USER_POPULATE } from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";
import { PlayerInjury } from "@/services/player-Injury-service.js";
import { Documents } from "@/services/documents-service.js";
import { PlayerPhysicalPerformance } from "@/services/player-physical-performance-service.js";
import { Homework } from "@/services/homework-service.js";
import AppTemplate from "../layouts/App";
import moment from "moment";
import SpinnerLoader from "../../components/LoadingSpinning";
import SortingByCreatedDate from "../../components/Sorting";
import PitchSelect from "../../components/PitchSelect";
// import { GadyflLeaguetable } from "@/services/leaguetable-service.js";
import { Teams } from "@/services/teams-service.js";
import { Games } from "@/services/games-service.js";
import { Chat } from "@/services/chat-service.js";
import { Trialists } from "@/services/trialist-service.js";
import { Pitches } from "@/services/coaches-pitches.js";
import { Comments } from "@/services/comments-service.js";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import StarRating from "vue-star-rating";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { where, orderBy, query, limit, collection, getDocs, onSnapshot, addDoc} from "firebase/firestore"
import db from "@/firebase/init.js";
import removeDuplicatesMixin from "@/mixin/removeDuplicates.js";
import goToPlayerMixin from "@/mixin/goToPlayer.js";
import checkIfUserHasMoreThanOneAccountMixin from "@/mixin/checkIfUserHasMoreThanOneAccount.js";
import extendSeasonEndDateToFullYearMixin from "@/mixin/extendSeasonEndDateToFullYear.js";
import setPitchZoneTextMixin from "@/mixin/pitchZoneText.js";
import dayjs from 'dayjs';

const Filter = require('bad-words'),
filterBadWords = new Filter();

export default {
  name: "Teams",
  mixins: [
    removeDuplicatesMixin,
    checkIfUserHasMoreThanOneAccountMixin,
    goToPlayerMixin,
    setPitchZoneTextMixin,
    extendSeasonEndDateToFullYearMixin,
  ],
  components: {
    AppTemplate,
    SpinnerLoader,
    Datepicker,
    DateRangePicker,
    SortingByCreatedDate,
    PitchSelect,
    StarRating,
  },
  data() {
    return {
      loadIngStuffText: "Loading",
      allHomeWorkData: [],
      newHomeWorkData: {
        title: "",
        information: "",
        videos: [
        {
          video_name: "",
          video_url: "",
          player_id: "",
          category: "",
        }
        ]
      },
      gameResponceValueChange: "",
      uploadedDocuments: [],
      listOfSelectedplayerIds: [],
      gamePerformanceAnalysisData: [],
      avarageGamePerformanceAnalysisData: {},
      fullSeasonCheckBoxData: false,
      teamTrainingStats: {
        averageAttendance: 0,
        averageScores: "",
        averageEffortScores: "",
      },
      teamStats: {
          wins: 0,
          losses: 0,
          draws: 0,
          goalsScored: 0,
          goalsConceded: 0,
          cleanSheets: 0,
        },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      chatGroupName: "",
      chatGroupData: [],
      selectedChat: {
        text: "",
      },
      selectedAllPlayerToggleValue: true,
      selectedChatMessages: [],
      trialistsData: [],
      allPlayerInATeamInjuryReports: [],
      allGameGoalScorersData: [],
      allTeamsGameResultsData: [],
      selectedTabGamesTraining:"teams-over-games-performance-tab",
      selectedTabTeamsOverview: "teams-over-view-tab",
      commentsData: [],
      togglePitchDetails: false,
      leagueTable: "",
      gameDeleteEditoccuranceValue: null,
      modalTypeNmae: "Edit",
      showTeamChange: false,
      recurringEvent: false,
      editMode: false,
      editGame: {
        address: "",
        away_team_goal_scorers: null,
        away_team_goals: "0",
        endTime: "",
        gameType: "",
        home_team_goal_scorers: null,
        home_team_goals: "0",
        id: "",
        kickOffTime: "",
        locationName: "",
        matchDate: "",
        meetTime: "",
        notes: "",
        opposition: "",
        pitchType: "",
        postCode: "",
        recurringEventType: null,
        result_added: "0",
        stripType: "",
        teamId: "",
        teamName: "",
        pitch_id: "",
        pitch_zone: null,
      },
      adminNavigationSelectedTeam: false,
      selectedGame: {},
      selectedGameAttendees: [],
      currentTeam: [],
      selectedUserValue: "",
      pitches: [],
      newGameData: {
        opposition: "",
        kickOffTime: "",
        matchDate: "",
        locationName: "",
        address: "",
        postCode: "",
        stripType: "",
        meetTime: "",
        endTime: "",
        notes: "",
        gameType: "Training",
        teamId: "",
        teamName: "",
        pitchType: "",
        recurringEventType: null,
        home_team_goals: 0,
        away_team_goals: 0,
        away_team_goal_scorers: null,
        home_team_goal_scorers: null,
        result_added: 0,
        pitch_id: "",
        pitch_zone: null,
      },
      thisWeeksGames: [],
      thisWeeksTraining: [],
      pastGames: [],
      pastTraining: [],
      dateRange: {
        startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        endDate: moment().add(2, "months").format("YYYY-MM-DD"),
      },
      updateValues: {},
      dateRangePickerLocaleData: {
        direction: "ltr",
        format: "mm/dd/yyyy",
        separator: " - ",
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        monthNames: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        firstDay: 0,
      },
      selectedTab: "games-tab",
      clubTeams: [],
      selectedTeam: "",
      leagueTeams: [],
      loadingStuff: false,
      weekSelectedNumber: "",
      selectedWeekMonSunday: "",
      weeklyDate: "",
      userData: {
        name: "",
        userType: "",
      },
      allGameAnalysisData: [],
      selectedGameAnalysisData: {
        gameId: null,
        teamId: null,
        teamName: null,
        coachName: null,
        coachId: null,
        age_group: null,
        matchDate: null,
        opposition: null,
        kickOffTime: null,
        penetrationScore: 0,
        penetrationComments: "",
        supportScore: 0,
        supportComments: "",
        mobilityScore: 0,
        mobilityComments: "",
        creativityScore: 0,
        creativityComments: "",
        transitionToAttackScore: 0,
        transitionToAttackComments: "",
        pressureScore: 0,
        pressureComments: "",
        coverScore: 0,
        coverComments: "",
        balanceScore: 0,
        balanceComments: "",
        compactnessScore: 0,
        compactnessComments: "",
        transitionToDefendScore: 0,
        transitionToDefendComments: "",
        generalComments: "",
        trainingComments: "",
      },
      teamGameResultsData: [],
      teamGameResultSlectedData: {},
      teamGameResultNewData: {},
      selectTypeOfGameoptions: [
        { value: "Training", text: "Training" },
        { value: "League game", text: "League game" },
        { value: "Cup game", text: "Cup game" },
        { value: "Friendly", text: "Friendly" },
      ],
      playerAvaiabilityTab: "going-tab",
      injuredPlayersList: [],
      noResponselayersList: [],
      hideRemoveTeamFilterButton: true,
      allTeamDataBeforFilterApplied: [],
      filterdTeamsData: [],
      toggleFilters: false,
      selectedFilterData: null,
      gameEventSelected: null,
      teamWhoScored: null,
      teamChatMessages: null,
      showallTeams: false,
      userHasMoreThanOneAccount: null,
      allGamesAndTrainingData: [],
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile", "getWeekNumber", "getCoachesUsersData"]),
  },
  watch: {
    selectedChatMessages: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => this.$refs["bottom"].value?.scrollIntoView({ behavior: "smooth" }));
      },
    },
  },
  async created() {
    //this.$route.params.teamData !== undefined
    this.teamChatMessages = collection(db, "team_chat_messages");
    this.userHasMoreThanOneAccount = this.checkIfUserHasMoreThanOneAccount();
    //console.log(this.$refs["bottom"]);
    //const bottom = this.$refs["bottom"].value = null;
    // const now = moment().format("MM-DD-YYYY");
    this.weekSelectedNumber = moment().isoWeek();
    this.setMonToSundayWeekDates();
    const clientId = JSON.parse(localStorage.getItem("the_w_selectedClientId"));
    this.selectedUserValue = clientId;

    this.loadingStuff = true;
    const response = await this.$store.dispatch(CHECK_PROFILE_HAS_DATA);

    if (response === true) {
      this.setUser();

      if (this.$route.params.teamData !== undefined) {
        this.getTeamsByUserId();
        this.getAllPitchesForClub();
      }

      if (
            this.userData.userType === "Admin" ||
            this.userData.userType === "Coach" ||
            this.userHasMoreThanOneAccount !== null
          ) {
            this.showallTeams = true;
          }

      if (this.userHasMoreThanOneAccount !== null) {
        this.getTeamsDataForMultiAccounts();
        this.getAllPitchesForClub();

      } else {
        this.getTeamsByUserId();
        this.getAllPitchesForClub();
      }

      this.loadingStuff = false;
    } else {
      this.getUserById(clientId).then((response) => {
        if (response === true) {

          if (this.userHasMoreThanOneAccount !== null) {
            this.getTeamsDataForMultiAccounts();
            this.getAllPitchesForClub();

          } else {
            this.getTeamsByUserId();
            this.getAllPitchesForClub();
          }

          if (
            this.userData.userType === "Admin" ||
            this.userData.userType === "Coach" ||
            this.userHasMoreThanOneAccount !== null
          ) {
            this.showallTeams = true;
          }

          if (
            this.userData.userType === "Admin" ||
            this.userData.userType === "Coach"
          ) {
            this.selectedUserValue = this.$route.params.trainingPlanUserId;
          } else {
            this.selectedUserValue = clientId;
          }
        }
      });
      this.loadingStuff = false;
    }
  },
  methods: {
    removeNumberFromStart(documentName) {
     return documentName.substring(documentName.indexOf('_')+1);
    },
    gameResponceChange(userId) {
      this.gameResponce(this.gameResponceValueChange, userId);
    },
    populateSelectedTeamVenue(event, gameType) {
      const selectedTeam = this.leagueTeams.find(team => team.id == event.target.value);

      console.log(selectedTeam);

      if (!selectedTeam) return; // Early return if no team is found
      const { teamName, address, name: locationName, postCode, pitchType, id } = selectedTeam;

      Object.assign(this[gameType], {
          pitch_id: id,
          opposition: teamName,
          address,
          locationName,
          postCode,
          pitchType: pitchType === 'Astro' ? '4G' : pitchType === 'Astro/Grass' ? 'Other' : pitchType
      });

    },
    formatDate(date) {
      if (date === null) {
        return "No Date";
      }
      return moment(date).format("MMMM Do YYYY");
    },
    formatDateForHuman(date) {
      return date !== undefined && moment(date).isValid()
        ? moment(date).format("MMMM Do YYYY")
        : "";
    },
    async deleteHomework(id) {
      const data = {
        id: id,
        action: "deleteHomework",
      }

      const response = await Homework.find(data).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.getAllHomeworkForTeam();

        this.$bvToast.toast("Homework successfully deleted.", {
          title: "Deleted",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
            
        }
    },
    async editHomework() {
      const data = {
        ...this.newHomeWorkData,
        created_date: moment().format("YYYY-MM-DD"),
        player_id: null,
        action: "editHomework",
        document_id: this.newHomeWorkData.id,
        teamId: this.selectedTeam.id,
        videos: []
      }

      const response = await Homework.find(data).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.getAllHomeworkForTeam();

        this.$bvModal.hide("addHomeworkModal");
        this.resetNewHomeWorkData();

        this.$bvToast.toast("Homework successfully updated.", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
            
        }
    },
    viewHomework(homeworkData) {
      this.newHomeWorkData = homeworkData;
      this.$bvModal.show("viewHomeworkModal");
    },
    async saveHomework() {
      const data = {
        ...this.newHomeWorkData,
        created_date: moment().format("YYYY-MM-DD"),
        player_id: null,
        teamId: this.selectedTeam.id,
        action: "saveHomework",
      }

      const response = await Homework.find(data).catch((error) => {
        console.log(error);
      });


      if (response.status === 200) {
        this.getAllHomeworkForTeam();

        this.$bvModal.hide("addHomeworkModal");
        this.resetNewHomeWorkData();

        this.$bvToast.toast("Homework successfully saved.", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.sendFBNotificationMessage("Homework added", "You have a new homework item to review.", [this.selectedTeam.age_group]);
            
        }
    },
    async getAllHomeworkForTeam() {
      const data = {
        action: "getAllHomeworkForTeam",
      	teamId: this.selectedTeam.id,
        season_start_date: localStorage.getItem("currentPlayingseasonStartDate"),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      }
      const response = await Homework.find(data).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.allHomeWorkData = response.data;
      }
    },
    resetNewHomeWorkData() {
      this.newHomeWorkData = {
        title: "",
        information: "",
        videos: []
      }
    },
    editHomeworkModalCall(homework) {
      this.newHomeWorkData = homework;
      this.showModal('addHomeworkModal');
    },
    addHomeWorkModalCall() {
      this.resetNewHomeWorkData();
      this.showModal('addHomeworkModal');
    },
    getPlayerAverageAttendance(teamMemberId) {
      let percentage = 0;
      let totalGames = this.selectedGamesEvents.length;
      const gamesAttended = this.teamsAttendedGamesAndTrainingData.filter(
        (game) => game.user_id === teamMemberId && game.going === 1
      );

      if (gamesAttended.length > 0) {
        percentage = (gamesAttended.length / totalGames) * 100;

        return Math.round(percentage);
      } else {
        return 0;
      }

    },
    checkGameTrainingAttendance(teamMemberId, gameKickoffTime) {
      const game = this.teamsAttendedGamesAndTrainingData.find(
        (game) => {
          if (game.kickOffTime === gameKickoffTime && game.user_id === teamMemberId) {
            return game;
          }
        }
      );

      if (game === undefined) {
        return "No Response";
      }

      if (game.going === 1) {
        return "Attended";
      }

      if (game.notGoing === 1) {
        return "Not Attended";
      }

      if (game.notGoing === 0 && game.going === 0) {
        return "No Response";
      }

    },
    teamsAttendedGamesAndTrainingBuild() {
      this.allGamesAndTrainingData = [
        ...this.pastGames,
        ...this.pastTraining
      ]
    },
    async getDocumentUploadsByTeamId() {
      const userData = {
        action: "getDocumentUploadsByTeamId",
        clubId: this.selectedTeam.clubId,
        teamId: this.selectedTeam.teamId,
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if(response.status === 200) {
        this.getClubDocumentUploads(response.data);
      }

    },
    async getClubDocumentUploads(otherUploadData) {
      const userData = {
        action: "getClubDocumentUploads",
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if(response.status === 200) {
        this.uploadedDocuments = [...response.data, ...otherUploadData];
      }
    },
    setBarVariant(value) {
      if (value > 0 && value <= 2) {
        return "danger";
      }
      if (value > 2 && value <= 3) {
        return "warning";
      }
      if (value > 3 && value <= 4) {
        return "primary";
      }
      if (value > 4 && value <= 5) {
        return "success";
      }
    },
    getTeamIdsFromUsers() {
      return this.userHasMoreThanOneAccount.map((user) => user.id);
    },
    async getTeamsDataForMultiAccounts() {
      const userData = {
        userIds: this.getTeamIdsFromUsers(),
        action: "getTeamsDataForMultiAccounts",
      };

      const responce = await Teams.find(userData).catch((error) => {
        console.log(error);
      });

      const cleanData = this.removeDuplicates(responce.data, "id");
      this.allTeamDataBeforFilterApplied = cleanData;
      this.clubTeams = this.removeDuplicates(responce.data, 'id');
    },
    async saveNewChatGroup() {
      if (this.chatGroupName !== "") {
        this.loadingStuff = true;
          const data = {
            action: "saveNewChatGroup",
            name: this.chatGroupName,
            usersInTheGroup: this.listOfSelectedplayerIds.toString(),
            ageGroup: this.selectedTeam.age_group,
            teamId: this.selectedTeam.id,
            playerId: this.userData.id,
          };

          const responce = await Chat.find(data).catch((error) => {
            console.log(error);
          });

          this.loadingStuff = false;
          if (responce.status === 200) {
            this.getTeamChatGroups();
            this.$bvModal.hide("chatGroupModal");
            this.chatGroupName = "";
            this.$bvToast.toast("Chat group added", {
              title: "Chat group added",
              toaster: "b-toaster-bottom-right",
              solid: true,
              appendToast: true,
              variant: "success",
            });
          }

      } else {
        this.chatGroupName = "";
      }

    },
    selectedAllPlayerToggle() {
      this.listOfSelectedplayerIds = [];

      if (this.selectedAllPlayerToggleValue) {
        this.currentTeam.map((player) => {
          this.listOfSelectedplayerIds.push(player.id)
        });
      }
    },
    async getTeamChatGroups() {
      this.loadingStuff = true;
      const data = {
        action: "getTeamChatGroupsForUser",
        teamId: this.selectedTeam.id,
      };

      const responce = await Chat.find(data).catch((error) => {
        console.log(error);
      });

      this.loadingStuff = false;
      if (responce.status === 200) {
        this.chatGroupData = responce.data;
      }
    },
    detachListener() {
      this.chatListener();
    },
    async getTeamChatMessages() {

      const q = query(
        this.teamChatMessages,
        where("chatId", "==", String(this.selectedChat.id)),
        where("clubId", "==", String(this.userData.clubId)),
        orderBy("createdDate", "asc"),
        limit(100)
      );

      this.chatListener = onSnapshot(q, (querySnapshot) => {
        this.selectedChatMessages = [];
        querySnapshot.forEach((doc) => {
        this.selectedChatMessages.push(doc.data());
      });

      });
    },
    async saveAppNotifications() {
     /* const docRef = await addDoc(collection(db, "app_notifications"), {
        chatId: '1',
        messageContent: filterBadWords.clean('helooooo'),
        userIds: "1,2,3,4,5,6,7,8",
        teamIds: "1,2,3",
        messageType: "sender",
        name: this.userData.name,
        userId: this.userData.id.toString(),
        media: "",
        userImageURL: this.userData.userImage,
        createdDate: new Date().toISOString(),
        clubId: this.userData.clubId
      }).then(() => {
            console.log("Document successfully written!");
          }).catch((error) => {
            console.error("Error writing document: ", error);
          });*/

    },
    async saveChatMessage() {
      const docRef = await addDoc(collection(db, "team_chat_messages"), {
        chatId: this.selectedChat.id.toString(),
        messageContent: filterBadWords.clean(this.selectedChat.text),
        messageType: "sender",
        name: this.userData.name,
        userId: this.userData.id.toString(),
        media: "",
        userImageURL: this.userData.userImage,
        createdDate: new Date().toISOString(),
        clubId: this.userData.clubId
      }).then(() => {
            console.log("Document successfully written!");
            this.sendFBNotificationMessage(`A new message has been posted to the ${this.selectedTeam.teamName} team chat`, [this.selectedTeam.age_group]);
            this.sendFBNotificationMessage(`A new message has been posted to the ${this.selectedTeam.teamName} team chat`, [], this.selectedTeam.coach_id);
          }).catch((error) => {
            console.error("Error writing document: ", error);
          });

    this.selectedChat.text = "";
    },
    checkIfGameHasAnalysis(gameId) {
      console.log(gameId);
      return this.allGameAnalysisData.length > 0
        ? this.allGameAnalysisData.some(
            (gameAnalysis) => {
              console.log(gameAnalysis.gameId === gameId);
              console.log(gameAnalysis.gameId);
              console.log(gameId);
             return gameAnalysis.gameId === gameId;
            }
          )
        : false;
    },
    async upDateGameAnalysisData() {
      this.loadingStuff = true;
      const data = {
        ...this.selectedGameAnalysisData,
        action: "upDateGameAnalysisData",
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data = "Game analysis updated") {
        this.$bvToast.toast("Game analysis updated", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.getGameAnalysisData();
        this.$bvModal.hide("gameAnalysisModal");
        this.loadingStuff = false;
      }
    },
    async getGameAnalysisData() {
      const data = {
        teamId: this.selectedTeam.id,
        season_start_date: this.selectedTeam.season_start_date,
        season_end_date: this.selectedTeam.season_end_date,
        action: "getGameAnalysisData",
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allGameAnalysisData = responce.data;
      }
    },
    async createNewGameAnalysis() {
      this.loadingStuff = true;
      const data = {
        ...this.selectedGameAnalysisData,
        action: "createNewGameAnalysis",
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data = "Game analysis added") {
        this.$bvToast.toast("Game analysis added", {
          title: "Added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
        this.getGameAnalysisData();

        this.$bvModal.hide("gameAnalysisModal");
        this.loadingStuff = false;
      }
    },
    resetSelectedAnalysisObject() {
      this.selectedGameAnalysisData = {
        gameId: null,
        teamId: null,
        teamName: null,
        coachName: null,
        coachId: null,
        age_group: null,
        matchDate: null,
        opposition: null,
        kickOffTime: null,
        penetrationScore: 0,
        penetrationComments: "",
        supportScore: 0,
        supportComments: "",
        mobilityScore: 0,
        mobilityComments: "",
        creativityScore: 0,
        creativityComments: "",
        transitionToAttackScore: 0,
        transitionToAttackComments: "",
        pressureScore: 0,
        pressureComments: "",
        coverScore: 0,
        coverComments: "",
        balanceScore: 0,
        balanceComments: "",
        compactnessScore: 0,
        compactnessComments: "",
        transitionToDefendScore: 0,
        transitionToDefendComments: "",
        generalComments: "",
        trainingComments: "",
      };
    },
    gameAnalysisModal(gameData, modelId, performanceData) {
      this.resetSelectedAnalysisObject();
      let gameDataId = gameData.id;
      if (performanceData !== undefined) {
        gameDataId = gameData.gameId;
      }

      if (this.checkIfGameHasAnalysis(gameDataId)) {
        // edit
        this.selectedGameAnalysisData = this.allGameAnalysisData.find(
          (gameAnalysis) => gameAnalysis.gameId === gameDataId
        );
        this.selectedGameAnalysisData.edit = true;

      } else {
        // new
      this.selectedGameAnalysisData.gameId = gameDataId;
      this.selectedGameAnalysisData.teamId = this.selectedTeam.id;
      this.selectedGameAnalysisData.teamName = this.selectedTeam.teamName;
      this.selectedGameAnalysisData.coachName = this.selectedTeam.name;
      this.selectedGameAnalysisData.coachId = this.selectedTeam.coach_id;
      this.selectedGameAnalysisData.age_group = this.selectedTeam.age_group;
      this.selectedGameAnalysisData.matchDate = gameData.matchDate;
      this.selectedGameAnalysisData.opposition = gameData.opposition;
      this.selectedGameAnalysisData.kickOffTime = gameData.kickOffTime;
      this.selectedGameAnalysisData.edit = false;
      }
      this.showModal(modelId);
    },
    chatDetailsModal(chatData) {
      this.selectedChat = chatData;
      this.getTeamChatMessages();

      this.showModal("chatDetailsModal");
    },
    getPlayerFromId(userId) {
      const player = this.currentTeam.find((player) => player.id === userId);
      return player ? player.name : null;
    },
    updateTeamWhoScored() {
      this.editGame.team = this.teamWhoScored;
    },
    goalScoredUserIdAddRemove(data) {
      data.target.checked === true
        ? (this.editGame.userId = data.target.id)
        : null;
    },
    goalAssistUserIdAddRemove(data) {
      data.target.checked === true
        ? (this.editGame.assist = data.target.id)
        : null;
    },
    resetGameResultsGoalSelection() {
      this.gameEventSelected = null;
      this.teamWhoScored = null;
    },
    removeFilter() {
      this.clubTeams = this.allTeamDataBeforFilterApplied;
      this.hideRemoveTeamFilterButton = true;
    },
    filterBy(arrayToFilter, filterBy) {
      const filterResult = this[arrayToFilter].filter(
        (pilot) => pilot[filterBy] === this.selectedFilterData
      );

      this.clubTeams = filterResult;
      this.hideRemoveTeamFilterButton = false;
    },
    noResponsePlayers() {
      if (this.selectedGameAttendees.length == 0) {
        this.noResponselayersList = this.currentTeam;
      } else {
        this.noResponselayersList = this.currentTeam.filter((player) => {
          return !this.selectedGameAttendees.some(function (attendee) {
            return player.id == attendee.user_id;
          });
        });
      }
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    async getComments(type_id) {
      this.commentsData = [];
      const data = {
        type_id: type_id,
        teamId: this.selectedGame.teamId,
        action: "getComments",
      };

      const responce = await Comments.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        console.log("responce");
        this.commentsData = responce.data;
      }
    },
    defaultPitchInputs(gameType) {
      this[gameType].pitch_id = "";
      this.newGameData.address = "";
      this.newGameData.locationName = "";
      this.newGameData.postCode = "";
      this.newGameData.pitchType = "";
      this.newGameData.pitch_zone = null;
      this.togglePitchDetails = false;
    },
    setPitchZones(pitchData, gameDataType) {
      if (
        pitchData.sectionOne !== false &&
        pitchData.sectionTwo === false &&
        pitchData.sectionThree === false
      ) {
        this[gameDataType].pitch_zone = "1";
      } else if (
        pitchData.sectionTwo !== false &&
        pitchData.sectionOne === false &&
        pitchData.sectionThree === false
      ) {
        this[gameDataType].pitch_zone = "2";
      } else if (
        pitchData.sectionThree !== false &&
        pitchData.sectionOne === false &&
        pitchData.sectionTwo === false
      ) {
        this[gameDataType].pitch_zone = "3";
      } else if (
        pitchData.sectionOne !== false &&
        pitchData.sectionTwo === false &&
        pitchData.sectionThree !== false
      ) {
        this[gameDataType].pitch_zone = "1,3";
      } else if (
        pitchData.sectionTwo !== false &&
        pitchData.sectionThree !== false &&
        pitchData.sectionOne === false
      ) {
        this[gameDataType].pitch_zone = "2,3";
      } else if (
        pitchData.sectionOne !== false &&
        pitchData.sectionThree === false &&
        pitchData.sectionTwo !== false
      ) {
        this[gameDataType].pitch_zone = "1,2";
      }
    },
    pitchChange(pitchData, gameType) {

      let seasonStartDate = moment().subtract(1, "months").format("YYYY-MM-DD");
      let seasonEndDate = moment().add(3, "months").format("YYYY-MM-DD");
      if (this.newGameData.matchDate !== "" || this.editGame.matchDate !== "") {
        if (this.newGameData.matchDate !== "") {
          this.setPitchZones(pitchData, "newGameData");
          seasonStartDate = moment(this.newGameData.matchDate).subtract(1, "months").format("YYYY-MM-DD");
          seasonEndDate = moment(this.newGameData.matchDate).add(3, "months").format("YYYY-MM-DD");
        }

        if (this.editGame.matchDate !== "") {
          this.setPitchZones(pitchData, "editGame");
          seasonStartDate = moment(this.editGame.matchDate).subtract(1, "months").format("YYYY-MM-DD");
          seasonEndDate = moment(this.editGame.matchDate).add(3, "months").format("YYYY-MM-DD");
        }
      }
      this[gameType].pitch_id = pitchData.pitchId || pitchData.id;
      const pitch = this.pitches.filter((pitch) => pitch.id == pitchData.pitchId);
      this[gameType].address = pitch[0].address;
      this[gameType].locationName = pitch[0].name;
      this[gameType].postCode = pitch[0].postCode;
      this[gameType].pitchType = pitch[0].pitchType;
      this.togglePitchDetails = true;
    },
    async getAllPitchesForClub() {
      const userData = {
        clubId: this.userData.clubId,
        action: "getAllPitchesForClub",
      };
      const responce = await Pitches.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.pitches = responce.data;
      }
    },
    sortedData(sortedDataArray) {
      console.log(sortedDataArray);
    },
    dateRangeReset() {
      this.dateRange = {
        startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        endDate: moment().add(2, "months").format("YYYY-MM-DD"),
      };

      this.getSelectedTeamsEvents(this.formatMatchDate(this.dateRange.startDate),this.formatMatchDate(this.dateRange.endDate));
    },
    async fullSeasonCheckBox() {

      if (this.fullSeasonCheckBoxData === true) {
        this.resetTeamsStats();
        const playingseasonStartDate = this.selectedTeam.season_start_date;
        const playingseasonEndDate = this.selectedTeam.season_end_date;

        await this.getSelectedTeamsEvents(playingseasonStartDate, playingseasonEndDate);
        this.pastGames.map(game => {
          if (game.result_added == '1') {
            this.countGamesResults(game);
          }
        })
        this.getGamePerformanceAnalysisData(playingseasonStartDate, playingseasonEndDate);

      } else {
        this.resetTeamsStats();
        this.performanceDateRangeSelected();
      }

    },
    async performanceDateRangeSelected() {
      await this.getSelectedTeamsEvents(this.formatMatchDate(this.dateRange.startDate), this.formatMatchDate(this.dateRange.endDate));

      this.pastGames.map(game => {
          if (game.result_added == '1') {
            this.countGamesResults(game);
          }
        })
      this.getGamePerformanceAnalysisData(this.formatMatchDate(this.dateRange.startDate), this.formatMatchDate(this.dateRange.endDate));

    },
    dateRangeSelected() {
      this.getSelectedTeamsEvents(this.formatMatchDate(this.dateRange.startDate), this.formatMatchDate(this.dateRange.endDate));
    },
    countGamesResults(game) {
      if (Number(game.home_team_goals) > Number(game.away_team_goals)) {
        this.teamStats.wins = this.teamStats.wins +1;
      }
      if (Number(game.home_team_goals) === Number(game.away_team_goals)) {
        this.teamStats.draws = this.teamStats.draws +1;
      }
      if (Number(game.home_team_goals) < Number(game.away_team_goals)) {
        this.teamStats.losses = this.teamStats.losses +1;
      }

      if (Number(game.away_team_goals) === 0) {
        this.teamStats.cleanSheets = this.teamStats.cleanSheets +1;
      }

    },
    checkGameResult(game) {
      if (Number(game.home_team_goals) > Number(game.away_team_goals)) {
        return "W";
      }
      if (Number(game.home_team_goals) === Number(game.away_team_goals)) {
        return "D";
      }
      if (Number(game.home_team_goals) < Number(game.away_team_goals)) {
        return "L";
      }
    },
    addNewGameresultModalClose() {
      this.editGame = this.emptyEditGameData();
    },
    addNewGameresultModal(game, modelId) {
      this.editGame = game;
      this.showModal(modelId);
    },
    async saveTeamGameResults() {},
    occuranceModalClose() {
      this.gameDeleteEditoccuranceValue = null;
    },
    checkUserIsAttendingGame() {
      let responce = false;
      if (this.selectedGameAttendees.length > 0) {
        this.selectedGameAttendees.filter((game) => {
          if (String(game.user_id) === String(this.userData.id)) {
            responce = game.going == 1 ? true : false;
          }
        });
      }

      return responce;
    },
    populatePlayerAttendanceData() {
      this.addDefaultDataToCurrentTeam();

      this.teamsAttendedGamesAndTrainingData.forEach((game) => {
        const player = this.currentTeam.find(
          (player) => player.id === game.user_id
        );

        if (player) {
          if (game.gameType === "League game" && game.going === 1) {
            player.gameAttendance += 1;
          }

          if (game.gameType === "Training" && game.going === 1) {
            player.trainingAttendance += 1;
          }

          if (game.gameType === "Cup game" && game.going === 1) {
            player.cupGameAttendance += 1;
          }
        }
      });
    },
    async getAllGamesAttendeesByTeamId(startDate, endDate) {
      if(startDate === "object") {
        this.dateRange.startDate = this.formatMatchDate(this.dateRange.startDate);
        this.dateRange.endDate = this.formatMatchDate(this.dateRange.endDate);
      }
      const userData = {
        teamId: this.selectedTeam.id,
        season_start_date: startDate,
        season_end_date: endDate,
        action: "getAllGamesAttendeesByTeamId",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.teamsAttendedGamesAndTrainingData = responce.data;
        this.populatePlayerAttendanceData();
      }
    },
    async getAllAttendeesForAGame() {
      const userData = {
        userId: this.userData.id,
        kickOffTime: this.selectedGame.kickOffTime,
        teamId: this.selectedGame.teamId,
        action: "getAllAttendeesForAGame",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });
      this.selectedGameAttendees = this.checkForInjuredPlayers(responce.data);
      this.noResponsePlayers();
    },
    checkForInjuredPlayers(attendeeList) {
      console.log();
      return attendeeList;
    },
    setResponce(responceType) {
      if (responceType === "Yes") {
        this.selectedGame.going = 1;
        this.selectedGame.notGoing = 0;
        this.selectedGame.noResponse = 0;
        this.selectedGame.injured = 0;
      } else {
        this.selectedGame.notGoing = 1;
        this.selectedGame.going = 0;
        this.selectedGame.noResponse = 0;
        this.selectedGame.injured = 0;
      }
    },
    addInjuryToPlayer() {
      this.currentTeam = this.currentTeam.map((player) => {
        const hasInjury = this.allPlayerInATeamInjuryReports.some(
          (injury) =>
            player.id == injury.player_id &&
            injury.injury_status !== "Fully recoverd"
        );
        player.injury = hasInjury;
        return player;
      });
    },
    async checkIfPlayerIsInjured() {
      console.log("the boy is ooot");
    },
    async getAllPlayerInClubInjuryReports() {
      const userData = {
        clubId: this.selectedTeam.clubId,
        season_start_date: this.selectedTeam.season_start_date,
        season_end_date: this.selectedTeam.season_end_date,
        action: "getAllPlayerInClubInjuryReports",
      };

      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allPlayerInATeamInjuryReports = responce.data;
        this.addInjuryToPlayer();
      }
    },
    async getAllPlayerInATeamInjuryReports() {
      const userData = {
        teamId: this.selectedTeam.id,
        action: "getAllPlayerInATeamInjuryReports",
      };

      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allPlayerInATeamInjuryReports = responce.data;
        this.addInjuryToPlayer();
      }
    },
    async checkIfMinsStatsDataExists(matchDate, gameType, responceType) {
      const data = {
        'action': "getPlayerMinsPerformanceDataForDate",
        'session_date': matchDate,
        'player_id': this.selectedUserValue,
      }
      const responce = await PlayerPhysicalPerformance.find(data).catch((error) => {
        console.log(error);
      });

      responce.data !== false
      ? this.editDayMinsStatsData(matchDate, gameType, responceType, responce.data.id)
      : this.addDayMinsStatsData(matchDate, gameType, responceType);
    },
    makeSelectedDayPlayerDataObject(matchDate, gameType) {
      this.selectedDayPlayerData = {
        'RPE_six_twenty': 0,
        'hi_accels': 0,
        'hi_decels': 0,
        'hi_distance': 0,
        'id': 0,
        'intensity': 0,
        'percent_of_max_speed': 0,
        'player_id': this.selectedUserValue,
        'playing_down_level': 0,
        'playing_up_level': 0,
        'session_date': matchDate,
        'session_distance': 0,
        'session_mins': 0,
        'session_type': gameType,
        'sprints': 0,
        'time_above_90_hrm': 0,
        'top_speed': 0,
        'vhi_distance': 0,
      };
    },
    async editDayMinsStatsData(matchDate, gameType, responceType, id) {
      this.makeSelectedDayPlayerDataObject(matchDate, gameType);
      if (responceType === 'Yes') {
          gameType === 'Training'
        ? this.selectedDayPlayerData.session_mins = 75
        : this.selectedDayPlayerData.session_mins = 90;
      } else {
        this.selectedDayPlayerData.session_mins = 0;
      }

      const data = {
        'action': "editDayMinsStatsData",
        ...this.selectedDayPlayerData,
        id: id
      }
      const responce = await PlayerPhysicalPerformance.find(data).catch((error) => {
        console.log(error);
      });
    },
    async addDayMinsStatsData(matchDate, gameType, responceType) {
      this.makeSelectedDayPlayerDataObject(matchDate, gameType);
      if (responceType === 'Yes') {
          gameType === 'Training'
        ? this.selectedDayPlayerData.session_mins = 75
        : this.selectedDayPlayerData.session_mins = 90;
      } else {
        this.selectedDayPlayerData.session_mins = 0;
      }
      const data = {
        'action': "addDayMinsStatsData",
        data: this.selectedDayPlayerData,
      }
      const responce = await PlayerPhysicalPerformance.find(data).catch((error) => {
        console.log(error);
      });
    },
    async gameResponce(responceType, userId) {
      this.setResponce(responceType);

      const userData = {
        userId: userId || this.userData.id,
        kickOffTime: this.selectedGame.kickOffTime,
        going: this.selectedGame.going,
        noResponse: this.selectedGame.noResponse,
        injured: this.selectedGame.injured,
        notGoing: this.selectedGame.notGoing,
        teamId: this.selectedGame.teamId,
        gameType: this.selectedGame.gameType,
        action: "setUserGameResponce",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });
      if (
        responce.data === "Game responce updated" ||
        responce.data === "Game responce added"
      ) {
        this.$bvModal.hide("viewGameDetails");
        this.getAllAttendeesForAGame();
        this.getAllGamesAttendeesByTeamId(this.dateRange.startDate, this.dateRange.endDate);
        this.checkIfMinsStatsDataExists(this.selectedGame.matchDate, this.selectedGame.gameType, responceType);
        this.gameResponceValueChange = "";
      }
    },
    setMonToSundayWeekDates() {
      const year = JSON.stringify(moment().year());
      const monday = moment()
        .year(year)
        .isoWeek(this.weekSelectedNumber)
        .day("monday")
        .startOf("day");
      this.selectedWeekMonSunday =
        monday.format("MMM D - ") + monday.add(6, "days").format("D YYYY");
      // this.depatchMonToSundayWeekDatesInLocalStorage();
    },
    toggleRecurringEvent() {
      if (this.recurringEvent === false) {
        this.newGameData.recurringEventType = null;
      }
    },
    editDeleteGameOptionsModal(game, modelId, type) {
      this.modalTypeNmae = type;
      if (type !== "Edit") {
        this.selectedGame = game;
        this.showModal(modelId);
      } else {
        this.editGame = structuredClone(game);
        if (this.gameDeleteEditoccuranceValue === null) {
          this.converTimesForUI();
          this.showModal("editGames");
        } else {
          this.showModal(modelId);
        }
      }
    },
    chatGroupModal() {
      this.showModal("chatGroupModal");
    },
    showGameDetails(game, modelId) {
      this.selectedGame = game;
      this.getAllAttendeesForAGame();
      this.showModal(modelId);
    },
    setClubTeamnewGameData(selectedTeam) {
      this.newGameData.teamId = selectedTeam.id;
      this.newGameData.teamName = selectedTeam.teamName;
    },
    async getUserGamesByTeam(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
        }
        return true;
      }
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });

        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
        }
        return true;
      }
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    hideModal(modelId) {
      this.$bvModal.hide(modelId);
    },
    showEditAddGamesModal(type, gameData) {
      this.editMode = false;
      this.showTeamChange = false;

      if (type === "edit") {
        this.emptySlectedGameData();
        this.converTimesForUI();
        this.addEditGames = gameData;
        this.editMode = true;
        this.showModal("editGames");
      } else {
        this.emptyNewGameData();
        let today = moment();
        this.weeklyDate = today._d;
        this.setClubTeamnewGameData(this.selectedTeam);
        //this.setTheWeekNumber(this.weeklyDate);
        //this.newSessionData.userId = this.selectedUserValue;
        this.showModal("addEditGames");
      }
      // this.coachesUsersData = this.getCoachesUsersData;
    },
    emptySlectedGameData() {
      this.selectedGame = "";
    },
    emptyEditGameData() {
      this.editGame = {
        address: "",
        away_team_goal_scorers: null,
        away_team_goals: "0",
        endTime: "",
        gameType: "",
        home_team_goal_scorers: null,
        home_team_goals: "0",
        id: "",
        kickOffTime: "",
        locationName: "",
        matchDate: "",
        meetTime: "",
        notes: "",
        opposition: "",
        pitchType: "",
        postCode: "",
        recurringEventType: null,
        result_added: "0",
        stripType: "",
        teamId: "",
        teamName: "",
        pitch_id: "",
        pitch_zone: null,
      };
    },
    emptyNewGameData() {
      this.newGameData = {
        opposition: "",
        kickOffTime: "",
        matchDate: "",
        locationName: "",
        address: "",
        postCode: "",
        stripType: "",
        meetTime: "",
        endTime: "",
        notes: "",
        gameType: "Training",
        teamId: "",
        teamName: "",
        pitchType: "",
        recurringEventType: null,
        home_team_goals: 0,
        away_team_goals: 0,
        away_team_goal_scorers: null,
        home_team_goal_scorers: null,
        result_added: 0,
        pitch_id: "",
        pitch_zone: null,
      };
    },
    resetTeamsStats() {
      this.teamStats = {
          wins: 0,
          losses: 0,
          draws: 0,
          goalsScored: 0,
          goalsConceded: 0,
          cleanSheets: 0,
        }
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;

      if (selectedTabValue === "full-team-tab") {
        this.getAllTrialistsForTeam();
      }

      if (selectedTabValue === "full-team-chat") {
        this.getTeamChatGroups();
      }

      if (selectedTabValue === "team-performance-tab") {
        this.resetTeamsStats();

        this.pastGames.map(game => {
          if (game.result_added == '1') {
            this.countGamesResults(game);
          }
        })

        this.getGamePerformanceAnalysisData(this.selectedTeam.season_start_date, this.selectedTeam.season_end_date,);
      }

      if (selectedTabValue === "teams-over-games-training-tab") {
        this.getTrainingPerformanceReportForTeam();
      }

      if (selectedTabValue === "team-documents-tab") {
        this.getDocumentUploadsByTeamId();
      }

      if (selectedTabValue === "team-attendance-tab") {
        this.teamsAttendedGamesAndTrainingBuild();
      }

      if (selectedTabValue === "homework-tab") {

        if (localStorage.getItem("currentPlayingseasonStartDate") === null) {

          let year = moment().year();

          localStorage.setItem(
          "currentPlayingseasonStartDate",
          this.selectedTeam.season_start_date
          );
          localStorage.setItem(
            "currentPlayingseasonEndDate",
            this.selectedTeam.season_end_date
          );

          localStorage.setItem(
            "currentPlayingSeason",
            `${year}`
          );

        }
        this.getAllHomeworkForTeam();
      }

      //pastGames
    },
    async getTrainingPerformanceReportForTeam() {
      const data = {
        action: "getTrainingPerformanceReportForTeam",
        teamId: this.selectedTeam.id,
      }
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
      }

    },
    getAllTrainingSessionIdsForTeam() {
      return this.pastTraining.map(trainingSession => trainingSession.id);
    },
    emptyThisWeeksTrainingAndGames() {
      this.thisWeeksGames = [];
      this.thisWeeksTraining = [];
    },
    checkForAdminNavigation(e) {
      if (!e.target) {
        this.adminNavigationSelectedTeam = true;
        return e.teamName;
      } else {
        return e.target.value;
      }
    },
    setSelectedTeam(e) {
      this.loadingStuff = true;
      const teamData = this.checkForAdminNavigation(e);

      if (this.userHasMoreThanOneAccount !== null || this.userData.userType === "Admin") {
        this.showallTeams = false;
      }

      let selectedTeamData = this.clubTeams.filter(function (team) {
        return team.teamName === teamData;
      });

      this.selectedTeam = selectedTeamData[0];
      localStorage.setItem(
          "currentPlayingseasonStartDate",
          this.selectedTeam.season_start_date
        );
        localStorage.setItem(
          "currentPlayingseasonEndDate",
          this.selectedTeam.season_end_date
        );
      this.getUserByTeamId();
      this.setClubTeamnewGameData(selectedTeamData[0]);
      //this.selectedTeam.league_link !== ""
      this.getgadyflLeagueTableData();
       // : (this.leagueTeams = []);
      this.emptyThisWeeksTrainingAndGames();
      this.getSelectedTeamsEvents(this.formatMatchDate(this.dateRange.startDate),this.formatMatchDate(this.dateRange.endDate));
      this.loadingStuff = false;
    },
    async getgadyflLeagueTableData() {

      const data = {
        action: "getAllOppositionTeams",
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.leagueTeams = responce.data;
      }
      /* const data = {
        action: "getGadyflLeaguetableData2010Adv",
        leagueTableURL: this.selectedTeam.league_link,
      };
      const responce = await GadyflLeaguetable.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.hasOwnProperty("data")) {
        if (responce.data === "") {
          console.log(responce);
        } else {
          let table = responce.data;
          this.leagueTable = responce.data;
          var htmlObject = document.createElement("div");
          htmlObject.innerHTML = table;
          var htmlTable = htmlObject.getElementsByTagName("table");
          for (const r of htmlTable[0].getElementsByTagName("tr")) {
            for (const c of r.getElementsByTagName("td")) {
              if (c.getElementsByTagName("div")[0]) {
                let div = c.getElementsByTagName("div")[0];
                this.leagueTeams.push(
                  div.getElementsByTagName("span")[0].innerText
                );
              }
            }
          }
        }
      }*/
    },
    filterGameEvents(allGameEventData) {
          this.thisWeeksGames = allGameEventData.filter((game) => {
          return (
            game.gameType !== "Training" &&
            game.matchDate >= moment().format("YYYY-MM-DD")
          );
        });

        this.thisWeeksTraining = allGameEventData.filter((game) => {
          return (
            game.gameType === "Training" &&
            game.matchDate >= moment().format("YYYY-MM-DD")
          );
        });
        this.pastGames = allGameEventData.filter((game) => {
          return (
            game.gameType !== "Training" &&
            game.matchDate < moment().format("YYYY-MM-DD")
          );
        });
      this.pastTraining = allGameEventData.filter((game) => {
        return (
          game.gameType === "Training" &&
          game.matchDate < moment().format("YYYY-MM-DD")
        );
      });
    },
    resetTrainingAndGamesData() {
      this.thisWeeksGames = [];
      this.thisWeeksTraining = [];
    },
    averageFromArrayOfNumbers(numbers) {
      let sum = 0;

      for (const num of numbers) {
        sum += num
      }

      return sum / numbers.length;
    },
    makeAvarageGamePerformanceAnalysis(data) {
      this.avarageGamePerformanceAnalysisData = {
        penetrationScoreTotal: this.averageFromArrayOfNumbers(data.map((performanceAnalysis) => performanceAnalysis.penetrationScore)),
        supportScoreTotal: this.averageFromArrayOfNumbers(data.map((performanceAnalysis) => performanceAnalysis.supportScore)),
        mobilityScoreTotal: this.averageFromArrayOfNumbers(data.map((performanceAnalysis) => performanceAnalysis.mobilityScore)),
        creativityScoreTotal: this.averageFromArrayOfNumbers(data.map((performanceAnalysis) => performanceAnalysis.creativityScore)),
        transitionToAttackScoreTotal: this.averageFromArrayOfNumbers(data.map((performanceAnalysis) => performanceAnalysis.transitionToAttackScore)),
        pressureScoreTotal: this.averageFromArrayOfNumbers(data.map((performanceAnalysis) => performanceAnalysis.pressureScore)),
        coverScoreTotal: this.averageFromArrayOfNumbers(data.map((performanceAnalysis) => performanceAnalysis.coverScore)),
        balanceScoreTotal: this.averageFromArrayOfNumbers(data.map((performanceAnalysis) => performanceAnalysis.balanceScore)),
        compactnessScoreTotal: this.averageFromArrayOfNumbers(data.map((performanceAnalysis) => performanceAnalysis.compactnessScore)),
        transitionToDefendScoreTotal: this.averageFromArrayOfNumbers(data.map((performanceAnalysis) => performanceAnalysis.transitionToDefendScore)),
      }

    },
    async getGamePerformanceAnalysisData(season_start_date, season_end_date) {
      const data = {
        action: "getGamePerformanceAnalysisData",
        teamId: this.selectedTeam.id,
        season_start_date: season_start_date,
        season_end_date: season_end_date,
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.makeAvarageGamePerformanceAnalysis(responce.data);
        this.gamePerformanceAnalysisData = responce.data;

        this.teamStats.goalsScored = this.pastGames.reduce((acc, games) => acc + games.home_team_goals, 0);
        this.teamStats.goalsConceded = this.pastGames.reduce((acc, games) => acc + games.away_team_goals, 0);

      }

    },
    async getAllTeamsPastResults(
      gameStartDateAndTime,
      gameEndDateAndTime,
      allTeamsIds
    ) {
      const data = {
        action: "getAllTeamsPastResults",
        teamIds: allTeamsIds,
        gameStartDateAndTime: gameStartDateAndTime,
        gameEndDateAndTime: gameEndDateAndTime,
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allTeamsGameResultsData = responce.data;
      }
    },
    getAllTeamsIdsFromTeamsEvents() {
      return this.clubTeams.map((event) => event.teamId);
    },
    async getTeamsGamesGoalScorers() {
      const data = {
        action: "getTeamsGamesGoalScorers",
        teamId: this.selectedTeam.id,
        pastGamesIds: this.pastGames.map((game) => game.id),
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allGameGoalScorersData = responce.data;
      }
    },
    async getSelectedTeamsEvents(startDate, endDate) {
      this.loadingStuff = true;
      this.resetTrainingAndGamesData();
      const data = {
        action: "getAllGamesEventsById",
        teamId: this.selectedTeam.id,
        gameFromDate: startDate,
        gameToDate: endDate,
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      this.selectedGamesEvents = responce.data;

      if (Array.isArray(responce.data) && responce.data.length > 0) {
        this.filterGameEvents(responce.data);
        this.getTeamsGamesGoalScorers();
      } else if (
        typeof responce.data === "object" &&
        Object.keys(responce.data).length > 0
      ) {
        this.filterGameEvents(responce.data);
        this.getTeamsGamesGoalScorers();
      } else {
        this.thisWeeksGames = [];
        this.thisWeeksTraining = [];
        this.pastGames = [];
        this.pastTraining = [];
      }

      if (this.userData.userType === "Admin") {
        const allTeamsIds = this.getAllTeamsIdsFromTeamsEvents();
        this.getAllTeamsPastResults(
          this.formatMatchDate(moment().subtract(3, "months")),
          this.formatMatchDate(moment()),
          allTeamsIds
        );
      }

      this.getGameAnalysisData();
      this.getAllGamesAttendeesByTeamId(this.dateRange.startDate, this.dateRange.endDate);

      this.loadingStuff = false;
    },
    async getTeamsByCoachid() {
      const data = {
        action: "getTeamsByCoachid",
        coach_id: this.userData.id,
      };

      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.clubTeams = this.removeDuplicates(responce.data, "id");
        this.allTeamDataBeforFilterApplied = this.clubTeams;
        this.selectedTeam = responce.data[0];
        this.getUserByTeamId();
        this.setClubTeamnewGameData(this.selectedTeam);
        this.getgadyflLeagueTableData();
        this.getSelectedTeamsEvents(this.formatMatchDate(this.dateRange.startDate),this.formatMatchDate(this.dateRange.endDate));
      }
    },
    async getTeamsByUserId() {
      this.userHasMoreThanOneAccount === null
      if (this.userData.userType === "Coach") {
        this.getTeamsByCoachid();
      } else {
        const data = {
          action: "getTeamsDataById",
          userId: this.userData.id,
        };

        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
          data.clubId = this.userData.clubId;
        }
        const responce = await Teams.find(data).catch((error) => {
          console.log(error);
        });
        this.allTeamDataBeforFilterApplied = responce.data;
        this.clubTeams = responce.data;

        this.selectedTeam = responce.data[0];

        if (this.$route.params.teamData !== undefined) {
          this.setSelectedTeam(this.$route.params.teamData)
        }

        this.getUserByTeamId();
        this.setClubTeamnewGameData(this.selectedTeam);
        this.getgadyflLeagueTableData();
        this.getSelectedTeamsEvents(this.formatMatchDate(this.dateRange.startDate),this.formatMatchDate(this.dateRange.endDate));

      }
    },
    deleteGames() {
      this.gameDeleteEditoccuranceValue === "one-Delete"
        ? this.deleteOnlyOneGame()
        : this.deleteAllGames();
    },
    async getAllTrialistsForTeam() {
      let data = {
        teamId: this.selectedTeam.id,
        clubId: this.selectedTeam.clubId,
        action: "getAllTrialistsForTeam",
      };
      const responce = await Trialists.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.trialistsData = responce.data.filter(trialists => trialists.outcome === 0);
      }
    },
    async deleteAllOccurrencesOfAGame() {
      let data = {
        id: this.selectedGame.id,
        action: "deleteGame",
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });
    },
    async deleteOnlyOneGame() {
      let data = {
        ...this.selectedGame,
        amendment_type: "one-Delete",
        action: "saveOneDeletedGame",
        season_start_date: localStorage.getItem("currentPlayingseasonStartDate"),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      this.$bvModal.hide("viewGameDeleteEditOptions");
      this.loadIngStuffText = "Deleting";
      this.loadingStuff = true;

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });
      if (
        responce.data === "Game added to games_removed_changed." ||
        responce.data === "game removed."
      ) {
        this.getSelectedTeamsEvents(this.formatMatchDate(this.dateRange.startDate),this.formatMatchDate(this.dateRange.endDate));
        this.loadingStuff = false;
      }
    },
    checkIfResultNeedsUpdated() {
      if (this.editGame.team !== undefined) {
        this.editGame.team === "home"
          ? (this.editGame.home_team_goals += 1)
          : (this.editGame.away_team_goals += 1);
      }
    },
    deleteAllGames() {},
    async editProgrammeSubmit(modelId) {
      this.loadIngStuffText = "Saving";
      this.loadingStuff = true;
      if (modelId == "addNewGameresultModal") {
        this.editGame.result_added = 1;
        this.checkIfResultNeedsUpdated();
        if (this.editGame.goal_type === undefined) {
          this.editGame.goal_type = "Open play";
        }
      }
      if (modelId !== "addNewGameresultModal") {
        this.converTimesForDB("editGame");
      }
      const data = { 
        ...this.editGame, 
        action: "saveEditGame",
        season_start_date: localStorage.getItem("currentPlayingseasonStartDate"),
        season_end_date: this.extendSeasonEndDateToFullYear()
       };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (
        responce.data === "Edit game responce saved successfully" ||
        responce.data === "Edit game responce updated" ||
        responce.data === "Game added to games_removed_changed." ||
        responce.data === "Game result added"
      ) {
        this.getSelectedTeamsEvents(this.formatMatchDate(this.dateRange.startDate),this.formatMatchDate(this.dateRange.endDate));
        this.resetGameResultsGoalSelection();
        this.hideModal(modelId);
        this.loadingStuff = false;
        this.$bvToast.toast(responce.data, {
          title: "success",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }

      this.sendFBNotificationMessage(`${this.editGame.gameType} - ${this.formatISOStringDateAndTime(this.editGame.kickOffTime)} - ${this.formatDateForHuman(this.editGame.matchDate)} has changed`, `Please review the changes to ${this.editGame.gameType} - ${this.formatDateForHuman(this.editGame.matchDate)} - ${this.formatMatchDate(this.editGame.matchDate)}`, [this.selectedTeam.age_group]);
    },
    async sendFBNotificationMessage(title, body, ageGroup) {
      if (ageGroup.includes("2009")) { ageGroup.push("2010")}
      if (ageGroup.includes("2010")) { ageGroup.push("2009")}
      if (ageGroup.includes("2007")) { ageGroup.push("2008")}
      if (ageGroup.includes("2008")) { ageGroup.push("2007")}
      const data = {
        action: "sendFBNotificationMessage",
        title: title,
        body: body,
        link: `${window.location.origin}/login`,
        age_group: ageGroup,
      }
      const responce = await Chat.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.$bvToast.toast("Notification sent", {
          title: "Notification sent",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }

    },
    async addEditProgrammeSubmit() {
      this.loadIngStuffText = "Saving";
      this.loadingStuff = true;
      this.hideModal("addEditGames");
      this.converTimesForDB("newGameData");
      const action = { action: "saveNewGame" };
      const data = { 
        ...this.newGameData, 
        ...action,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Game added.") {
        this.getSelectedTeamsEvents(this.formatMatchDate(this.dateRange.startDate),this.formatMatchDate(this.dateRange.endDate));
        this.loadingStuff = false;
        let body = "";
        if (this.newGameData.gameType !== 'Training') {
          body = `${this.newGameData.gameType} vs  ${this.newGameData.opposition}. Kickoff: ${this.formatISOStringDateAndTime(this.newGameData.kickOffTime)} Meet: ${this.time(this.newGameData.meetTime)} Venue: ${this.newGameData.locationName} ${this.newGameData.address} ${this.newGameData.postCode}`
        } else {
          body = `${this.newGameData.gameType} Kickoff: ${this.formatISOStringDateAndTime(this.newGameData.kickOffTime)} Venue: ${this.newGameData.locationName} ${this.newGameData.address} ${this.newGameData.postCode}`
        }
        this.sendFBNotificationMessage(this.newGameData.gameType, body, [this.selectedTeam.age_group])
      }
    },
    addDefaultDataToCurrentTeam() {
      this.currentTeam = this.currentTeam.map((player) => {
        player.trainingAttendance = 0;
        player.gameAttendance = 0;
        player.cupGameAttendance = 0;
        return player;
      });
    },
    async getUserByTeamId() {
      this.currentTeam = [];
      const userData = {
        teamId: this.selectedTeam.id,
        action: "getUserByTeamId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.currentTeam = responce.data;
        this.addDefaultDataToCurrentTeam();
        //this.getAllPlayerInATeamInjuryReports();
        this.getAllPlayerInClubInjuryReports();
        this.getAllGamesAttendeesByTeamId(this.dateRange.startDate, this.dateRange.endDate);
        this.listOfSelectedplayerIds = this.currentTeam.map(player => player.id);
      }
    },
    converTimesForDB(objectName) {
      if (this[objectName].endTime !== "") {
        this[objectName].endTime = this.changeTimeToTimeAndDate(
          this[objectName].endTime,
          this.formatMatchDate(this[objectName].matchDate)
        );
      }

      if (this[objectName].meetTime !== "") {
        this[objectName].meetTime = this.changeTimeToTimeAndDate(
          this[objectName].meetTime,
          this.formatMatchDate(this[objectName].matchDate)
        );
      }

      if (this[objectName].kickOffTime !== "") {
        this[objectName].kickOffTime = this.changeTimeToTimeAndDate(
          this[objectName].kickOffTime,
          this.formatMatchDate(this[objectName].matchDate)
        );
      }

      if (this[objectName].matchDate !== "") {
        this[objectName].matchDate = this.formatMatchDate(
          this[objectName].matchDate
        );
      }
    },
    converTimesForUI() {
      if (
        this.editGame.endTime !== undefined &&
        this.editGame.endTime !== "" &&
        this.editGame.endTime.includes("000Z")
      ) {
        this.editGame.endTime = this.changeTimeAndDateToTime(
          this.editGame.endTime
        );
      }

      if (
        this.editGame.meetTime !== undefined &&
        this.editGame.meetTime !== "" &&
        this.editGame.meetTime.includes("000Z")
      ) {
        this.editGame.meetTime = this.changeTimeAndDateToTime(
          this.editGame.meetTime
        );
      }

      if (
        this.editGame.kickOffTime !== undefined &&
        this.editGame.kickOffTime !== "" &&
        this.editGame.kickOffTime.includes("000Z")
      ) {
        this.editGame.kickOffTime = this.changeTimeAndDateToTime(
          this.editGame.kickOffTime
        );
      }

      if (this.editGame.matchDate !== "") {
        this.editGame.matchDate = this.formatMatchDate(this.editGame.matchDate);
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    dayMonthYear(date) {
      return moment(date).format("D MMM YYYY");
    },
    formatDates(date) {
      return moment(date).format("DD MMM");
    },
    getDayFromDate(date) {
      return moment(date).format("dddd");
    },
    getMonthFromDate(date) {
      return moment(date).format("MMMM");
    },
    getDayDateFromDate(date) {
      const dateString = `${date.year}-${date.month}`;
      const fullDate = moment(date.year)
        .month(date.month)
        .weekday(date.weekday);

      return moment(fullDate).format("D");
    },
    changeTimeToTimeAndDate(timeString, date) {
      return `${date}T${timeString}:00.000Z`;
    },
    changeTimeAndDateToTime(dateAndTime) {
      return dateAndTime.substring(11, 16);
    },
    formatISOStringDateAndTime(timeDateString) {
      return moment(timeDateString).utc().format("h:mm a");
    },
    formatMatchDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    shortKickoffTime(date) {
      return moment(date).format("h:mm a DD MMM");
    },
    time(date) {
      return moment(date).format("h:mm a");

    },
    getDayNumberFromDate(date) {
      return moment(date).format("D");
    },
    getDayAndDate(date) {
      return moment(date).format("dddd, MMMM Do YYYY");
    },
    getTimeFromDate(date) {
      return moment(date).format("HH.mm");
    }
  },
};
</script>
<style scoped lang="scss">

.homework-list {
    width: 100%;
    min-width: 33rem;
}

.team-tab-content {
  overflow:scroll;
}

.trialist {
  color:#b69144;
}

.team-attendance-wrapper {
    padding: 2rem;
    overflow: scroll;

    .attendanceTable {
      min-width: 86rem;
      th {
        font-size: 0.8rem;
        border-bottom: 1px solid #bec3c7;
        min-width: 13rem;
        text-align: center;
      }

      tr:nth-child(odd) {
        background:white;
      }

      tr:nth-child(even) {
        background:#eff4f8;
        
      }

      tr {
        border-top: 1px solid #bec3c7;
        td:first-of-type {
          background:#e3e9ed;
          border-bottom: 1px solid #bec3c7;
        }

        td {
          border-right: 1px solid #bec3c7;
          text-align: center;
          line-height: 1rem;

           p {
            margin:0;
           }
        }
      }
    }
}

.documents-tab {
  display: flex;
  flex-wrap: wrap;

  .documents-wrapper {
    display: flex;
  flex-wrap: wrap;
  }

  .document {
    max-width: 14rem;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    border-radius: 4px;
    box-shadow: 0 4px 16px rgba(138, 138, 138, 0.3);
    border: 1px solid transparent;

    &:hover,
    &:focus {
      border: 1px solid #dcca0d;
    }

    h3 {
      margin-top: 1rem;
      font-size: 1rem;
      overflow: hidden;
    white-space: nowrap;
    text-transform:inherit;
    }

    img {
      width: 40%;
    }
  }
}

.performance-bar span {
    font-weight: bold;
    border-radius: 100px;
}

.past-games-performance {
  width:100%;

  .main-stats {
    display: flex;
  flex-wrap: wrap;
  gap:0.5rem;

  div {
    border:1px solid #e1e5e9;
    padding:0.5rem;
    min-width: 10rem;

    h3 {
      font-size: 0.8rem;
    }

    span {
      font-size: 2rem;
      font-weight: 700;
    }
  }
  }

}

.past-games-performance-analysis {
  margin-top:2rem;
}

.games-performance-analysis-stats {
  width:100%;
  display: flex;
  flex-wrap: wrap;
  gap:1rem;
  margin-top:2rem;

  .stats-overview {
    border:1px solid #e1e5e9;
    border-top:3px solid;
    padding:1rem;
    min-width: 100%;

    h3 {
      font-size:1rem;
    }

    .stats-items {
      width:100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      p {
        min-width: 14rem;
      }

    }
  }

}
.padding-left {
  padding-left: 2.3rem;
}

.venue-text {
  position: relative;
  margin-left: 2.3rem;
  display: block;
  margin-bottom: 1rem;
}

.venue-text .b-icon {
  position: absolute;
  left: -37px;
  top: 0;
}

.team-chat-wrapper {
  padding: 1rem;
}

.season_info {
  margin-top: 1rem;
  p {
    font-size: 1.3rem;
    margin: 0;
  }
}

.player-photo {
  border-radius: 100px;
  border: 1px solid #c3cdd5;
  width: 50px;
  height: 50px;
}

.injury-table-style {
  border: 1px solid red;
  border-left: 3px solid red;
}

.team-content {
  display: flex;
  flex-wrap: wrap;
}

.player-game-numbers {
  ul.tabs {
    margin-bottom: 1rem;
  }
}

.filter-small {
  height: 2.5rem;
  border: transparent;
  color: #9ea7b0;

  &:hover,
  &:focus {
    background: transparent;
    color: #7c838a;
    border: transparent;
    outline: transparent;
    box-shadow: 0 0 0 0rem;
  }
}

.remove-icon-button {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

.very-small-two-numbers {
  width: 5rem;
}

.w-a-style {
  .centre-icon {
    text-align: center;
  }
  .green-success {
    color: #08a10f;
    border: 1px solid #08a10f;
    text-align: center;
    border-radius: 100px;
    display: block;
    width: 3rem;
    height: 3rem;
    line-height: 1rem;
    margin: 1rem auto 0 auto;
    background: #d5f1d6;
  }
}

.red-warning {
  color: white;
  background: #d28d9e;
}

.attacting-options, .defending-options {
    border: 1px solid #ced4da;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 4px;
}

.game-analysis-details {
  margin-bottom:1rem;
  p {
    margin: 0;
  }
}

.team-chat-wrapper {
    width: 100%;
    .team-chat-list-item {
      border-bottom: 1px solid #c3cdd5;
      padding: 2rem;
      margin-bottom: 2rem;

      img {
        width:4rem;
      }

      &:hover, &:focus {
        background: #c3cdd5;
        cursor: pointer;

      }
    }
}

.chat-messages {
  overflow-y: scroll;
    max-height: 50rem;
    margin-bottom: 10rem;
    position: relative;

  .chat-details {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    img {
        height: 4rem;
        border-radius: 100px;
        border: 1px solid;
      }

    .chat-wrapper {
      background: #ece9e9;
    margin: 0 1rem 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    color: #13519d;
    max-width: 66%;

    .chat-name-time-wrapper {
      display: flex;
      span.chat-name {
        display: block;
        font-size: 0.9rem;
        color: #2a8ce2;
      }

      span.chat-time {
      font-size: 0.8rem;
      }

    }
    
  }

  }

  .chat-details.receiver {
    justify-content: flex-end;

    .chat-wrapper {
      background: #c9ffe0;
    }
  }
  
}

.message-input {
      position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background: white;
    padding: 1rem;

    .form__item {

      input.form__input {
        width: 90%;
        margin-right: 1rem;
      }
    }
    
    }

    .user-select-wrapper {
      height:20rem;
    }

    .player-list {
      border-bottom: 1px solid #dee2e6;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
      img {
        width: 50px;
        margin-right: 0.5rem;
        border-radius: 100px;
        height: 50px;
        margin-bottom: 0.5rem;
      }

      &:hover, &:focus {
        cursor: pointer;
        background: #f8f8f8;
      }
    }

    .form__input.full-size {
      width:98%;
    }

    .teams {
    span {
      display: inline-block;
    font-size: 1.1rem;
    line-height: 0.6rem;
    margin-bottom: 0.5rem;
    color:#403436;}
    }


    @media screen and (min-width: 1024px) {

      .games-performance-analysis-stats {

.stats-overview {
  border:1px solid #e1e5e9;
  border-top:3px solid;
  padding:1rem;
  min-width: 30.8rem;

  }
}
    }

</style>
